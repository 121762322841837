export default {
    generalText: {
        loadingSidebar: "Cargar modulos",
        welcome: "Bem-vindo",
        header: "EXPERTS EM MOBILIDADE, GESTÃO E TERCEIRIZAÇÃO DE FROTAS",
        skip: "Pular",
        next: "Próximo",
        accept: "Aceitar",
        cancel: "Cancelar",
        call: "Chamar",
        send: "Enviar",
        confirm: "Confirme",
        type: "Tipo",
        goToModule: "Acessar",
        soon: "Em breve",
        continue: "Prosseguir",
        error: "Erro",
        advice: "Aviso",
        correct: "Correto",
        return: "Voltar",
        other: "Outro",
        delete: "Remover",
        yes: "Sim",
        no: "Não",
        na: "N/A",
        edit: "Atualizar",
        gender: "Sexo",
        telephone: "DDD + Telefone",
        cellphone: "DDD + Celular",
        zipCode: "CEP",
        help: "Assistencia 24hs e Guincho",
        helpInfoTitle: "Atençāo",
        helpInfoBody: "Este telefone deve ser usado apenas para solicitação de assitência 24hs e guincho em caso de pane ou acidente.",
        novelties: "Novidades",
        vehicle: "Veículo",
        genders: ["Homem", "Mulher"],
        km: "Atualizar quilometragem",
        bad: "Mal",
        excellent: "Excelente",
        phonePlaceHolderBra: "(DDD) + Telefone",
        phonePlaceHolderMx: "Telefone",
        searchPlaceHolder: "Buscar",
        update: "Atualizar",
        register: "Solicitar registro",
        clic: "Clique aqui",
        helpButtonPremium: "Atendimento Premium",
        updateButton: "Atualizar",
        updateMessage: "Atualização disponível! \n Atualize a sua versão do My ALD e desfrute de todas as melhorias que a ALD coloca a sua disposição. \n (Atualizacao obrigatória para utilização do app)",
        timeLineAvaliable: "Em breve, seus aplicativos serão devolvidos aqui! \n Em caso de dúvida, ligue para o Centro de Assistência ao Motorista.",
        corregir: "Corrigir",
        forgotPassword: "Esqueci minha senha",
        active: "Ativo",
        finalized: "Finalizado",
        logout: "Fechar Sessão",
        at: ' as ',
        aldAutomotive: "ALD Automotive",
        download: 'Baixar',
        request: "SOLICITAR"
    },
    NavBar: {
        notifications: "Notificações",
        language: "Idioma",
        profile: "Perfil",
        settings: "Configuração",
        logout: "Logout"
    },
    AcceptCookies: {
        advice: "AVISO",
        content: 'Antes de começar, leia e aceite as nossas Políticas e Termos atuais:',
        return: "Voltar",
        continue: "Continuar"
    },
    resetSenha: {
        title: "Atenção",
        expiredToken_1: "A sua senha temporária tem duração de 48 horas e ela já expirou!",
        expiredToken_2: "Por gentileza, responda o e-mail no qual você recebeu esta senha da nossa equipe de atendimento, informando que você precisa de uma nova senha temporária pois esta já se encontra expirada",
        titleSuccess: "Sucesso!",
        titleWarning: "Aviso!",
        succesToken: "A sua nova senha foi atualizada!",
        continue: "OK",
        formFieldsMessages: {
            requiredEmail: "O campo de e-mail é obrigatório",
            errorEmail: "O e-mail inserido não é válido",
            shortPass: "A senha deve conter no mínimo 8 e até 15 caracteres",
            longPass: "A senha digitada é muito longa",
            fieldRequired: "O campo é obrigatório",
            samePass: "As senhas devem ser as mesmas",
            passwordCriteria: "A senha deve conter pelo menos uma letra minúscula, uma letra maiúscula, um número, um caracter especial (!@#$%^&*) e nenhum espaço."
        },
    },
    login: {
        title: "Olá, estamos felizes por ver você aqui!",
        userPlaceholder: "Entre com seu nome do usuário",
        userInput: "Usuário",
        passwordPlaceholder: "Coloque sua senha",
        passwordInput: "Senha",
        passwordInput_2: "Senha",
        rememeberSession: "Lembrar usuário e senha",
        rememberPassword: "Recuperar senha",
        signIn: "Iniciar sessão",
        correct: "Senha correta",
        infoMessages: "Por motivos de segurança, é necessário que você realize a troca da sua senha.",
        passwordLabel_2: "Digite uma nova senha:",
        passwordLabel_3: "Confirme a nova senha:",
        continue: "CONTINUAR",
        tokenInfo: "Insira o TOKEN de 6 dígitos que enviamos ao e-mail ",
        tokenHelp: "Caso não tenha recebido o e-mail verifique a caixa de spam.",
        resendToken: "Reenviar TOKEN",
        confirm: "Confirmar",
        receivedToken: "Não recebeu o TOKEN?",
        help: "Precisa de ajuda?",
        tokenSuccess: "O token foi enviado por correio, verifique a sua caixa de correio.",
        confirmHelpToken: "Sua solicitação de suporte foi enviada com sucesso, nossa equipe de suporte entrará em contato para ajudá-lo a acessar sua conta.",
        errors: {
            auth: [
                "Senha incorreta",
                "Erro de rede (internet)",
                "Não encontramos nenhum veículo associado a sua conta. Retorne e verifique as informações inseridas ou fale com o gestor da frota.",
                "Ocorreu um erro ao carregar suas informações, tente novamente mais tarde",
                "Encontramos um problema com o seu usuário. Por gentileza entre em contato com a sua equipe de Gestão Operacional de Clientes da ALD para obter suporte!",
                "Não encontramos este usuário ativo no My ALD Manager. Para solicitar acesso, fale com seu Gerente de Contas",
                "Você está tentando iniciar sessão com um usuário tipo Condutor. Essa aplicação é destinada apenas a gestores de frota. Por gentileza, utilize o aplicativo mobile (My ALD) disponível na loja virtual do seu celular.",
                "An error occurred while sending the token",
                "O token é inválido",
                "O token expirou, por favor gere um novo.",
            ],
            fields: "Os campos não podem estar vazios",
            userField: "O campo usuário não pode estar vazio",
            passField: "O campo senha não pode estar vazio",
            tokenField: "Por favor insira o token antes de confirmar",
        }
    },
    categoriesNames: [
        /*01*/ "Minha Frota",
        /*04*/ "Relatórios",
        /*05*/ "Meus serviços",
        /*06*/ "My ALD condutores",
        /*04*/ "MyALD Fornecedores",
    ],
    dashboardNames: [
        /*00*/ "ALD NET",
        /*01*/ "IFRS16",
        /*02*/ "Manutenção",
        /*03*/ "Administração da frota",
        /*04*/ "ALD SAT",
        /*05*/ "Perfil",
        /*06*/ "ALD NET",
        /*07*/ "Relatórios da Frota",
        /*08*/ "Meus Documentos",
        /*09*/ "Home",
        /*10*/ "Notificações"
    ],
    profileConfiguration: { // Translate
        profile: {
            title: "Perfil",
            message: "Este campo só modificará o endereço de e-mail onde as mensagens chegarão.",
            inputs: {
                name: "Nome",
                email: "Email",
                cellphone: "(DDD) + Celular",
                birthdate: "data de nascimento",
                rfc: "CPF",
                postalCode: "CEP",
                adress: "Endereço"
            },
            success: [
                /*00*/ "Imagem carregada corretamente",
                /*01*/ "Dados atualizados corretamente"
            ],
            errors: [
                /*00*/ "Ocorreu um erro ao carregar a imagem",
                /*01*/ "Ocorreu um erro ao atualizar os dados",
                /*02*/ "O email informado não é válido.",
                /*03*/ "O nome de usuário ou e-mail fornecido não existe",
                /*04*/ "O CPF inserido não é válido"
            ]
        },
        account: {
            title: "Configuração da conta",
            textDescription: "Digite sua nova senha, lembre-se de que ela deve conter pelo menos uma letra maiúscula e um número.",
            currentPassword: "Senha atual",
            password: "Digite a senha",
            confirmPassword: "Confirmar senha",
            selectLanguage: "Selecione o idioma de sua preferência",
            forgotPassMessage: "Digite seu usuário (CPF para gestores de frota)",
            languages: [
                { value: "es", text: "Espanhol" },
                { value: "en", text: "Inglês" },
                { value: "pt", text: "Português" },
                { value: "fr", text: "Francês" }
            ],
            success: [
                /*00*/ "Senha atualizada com sucesso",
                /*01*/ "Alteração de idioma realizada",
                /*03*/ "Email de recuperação de senha enviado com sucesso"
            ],
            errors: [
                /*00*/ "Ocorreu um erro ao atualizar a senha",
                /*01*/ "Ocorreu um erro ao alterar o idioma",
                /*02*/ "O usuário ou e-mail de recuperação não pode estar vazio",
                /*03*/ "A senha não pode conter mais de 10 caracteres",
                /*04*/ "A senha não pode conter caracteres especiais"
            ]
        },
        portal: {
            title: "Configuração do portal",
            lateralMenu: "Menu lateral fixo",
            toolTip: "Se ativo: o menu lateral à esquerda será sempre visível",
        },
        alerts: {
            title: "Configurações de alerta",
            textDescription: "Ative e desative as notificações que desejar, você também pode recebê-las por e-mail.",
            checkbox: "Receba também por email.",
            slider1: "Serviços Concluídos",
            slider2: "Delivery",
            slider3: "Documentos",
            success: [
                /*00*/ "Alertas configurados corretamente",
                /*01*/ "A sua senha foi atualizada com sucesso!",
            ],
            errors: [
                /*00*/ "Ocorreu um erro ao configurar os alertas",
                /*01*/ "O tempo de redefinição da senha usando este link expirou, Retorne ao My ALD e solicite novamente em Esqueci Minha Senha um novo Link. Atençāo: cada link expira em 30 minutos após o seu envio!",
                /*02*/ "A mudança de senha não pôde ser realizada",
                /*03*/ "A senha deve ser diferente das ultimas 5",
            ]
        }
    },
    header: {
        placeholderInput: "Veículo, Placa, Cliente ...",
        search: "Pesquisar",
    },
    ifrs16: {
        types: [
            /*00*/ "IFRS16 Main Report",
            /*01*/ "IFRS16 Event Report"
        ],
        inputs: [
            /*00*/ "Relatórios",
            /*01*/ "Data de início",
            /*02*/ "Data de corte",
            /*03*/ "Data final"
        ],
        sendMessage: "Enviar relatório por email",
        success: [
            /*00*/ "Relatório enviado com sucesso!"
        ],
        errors: [
            /*00*/ "Ocurreu um erro ao enviar o relatório"
        ]
    },
    contracts: {
        downloadButton: "Baixar arquivo de atualização",
        uploadButton: "Carregar arquivo de atualização",
        titleSoon: "EM BREVE",
        subtitle: 'Neste módulo você pode visualizar e gerenciar a atualização de dados de seus veículos.',
        messageSoon: "Muito em breve, aqui no seu portal My ALD para gestores de frotas, colocaremos a sua disposição esta nova funcionalidade com a qual você poderá solicitar atualização de contrato dos veículos da sua frota.",
        slogan: "Aguarde, em breve! A mobilidade está mudando…",
        uploadConfirmation: 'Você tem certeza de que deseja fazer o upload do seguinte arquivo: ',
        filters: {
            title: "Filtros",
            contract: "Contrato",
            client: "Empresa",
            date: "Encontro",
            plates: "Placa",
            button: "Filtrar",
            noSerie: "Nº de  série (chassi)",
            status: "Status",
            clean: "Limpar filtros",
            select: "Selecionar"
        },
        tableColumns: {
            Brand: "Marca",
            Client: "Empresa",
            ClientNumber: "Cód. da empresa",
            ContractId: "ID do contrato",
            ContractNumber: "Nº do contrato",
            ContractStatus: "Status",
            ContractStatusColombia: "Status Contrato",
            ContractStatusString: "",
            ContractType: "Tipo de contrato",
            ContractedMonths: "Meses contratados",
            CostCenter: "Centro de custo",
            Description: "Descrição",
            DescriptionUO: "Descrição UO",
            DirectionArea: "Área",
            EndDate: "Data final prevista",
            FleetGroup: "Grupo",
            FuelConsumption: "Consumo de gasolina",
            KMContracted: "KM contratado",
            KMDateUpdate: "Data de atualização KM",
            KMStart: "Último KM",
            KMUpdate: "Atualização KM",
            LicensePlate: "Placa",
            Management: "Manutenção",
            Model: "Modelo",
            NoEco: "",
            PriceOption: "Opção de preço",
            PriceWithOption: "Preço com opção",
            Register: "Registro",
            SellPrice: "Preço de venda",
            SerialNumber: "NºSerie (VIN/Chassi)",
            StartDate: "Data inicial do contrato",
            Tyre: "",
            UserName: "Nome do usuário",
            VP: "",
            VehiclePrice: "Preço do veículo",
            VehicleType: "Tipo de Veículo",
            VehicleTypeDescription: "Descrição do tipo de veículo",
            id: "ID",
            Active: "Ativo",
            Inactive: "Inativo",
            DateNotRegistered: "Data Não Registrada",
            DateNotRegisteredKM: "Nenhuma atualização foi feita",
            CRLV: "CRLV"
        },
        success: [
            /*00*/ "Arquivo carregado com sucesso"
        ],
        errors: [
            /*00*/ "Ocorreu um erro ao enviar o arquivo de atualização",
            /*01*/ "Ocorreu um erro ao trazer os contratos"
        ],
        updateKM: {
            updateKM: "Atualização de quilometragem",
            buttonUpdateKM: "Atualizar quilometragem",
            currentKM: "Ultima KM",
            newKM: "Nova KM",
            placeholderNewKM: "Digite a nova KM",
            continue: "Deseja prosseguir?",
            alertKMBelow: "KM informada é menor que a anterior",
            alertKMGreater: "As seguintes placas têm um valor superior a 450000",
            alertKMSuccess: "Atualizações enviadas com sucesso! Em até 72 horas as informações estarão atualizadas em nossos sistemas internos. Obrigada!",
        },
        updateKMerrors: {
            emptyPlate: "Foi encontrada uma fila sem placa atribuída",
            emptyCurrentKM: "A Última KM está vazia na placa",
            emptyCurrentKMHint: "Isto é obtido selecionando a placa da lista",
            emptyNewKM: "A Nova KM está vazia na placa",
            invalidKMZero: "A Nova KM não pode ser zero na placa",
            invalidKMEqual: "A Nova KM tem que ser diferente da Última KM na placa",
            failConnectionGetKM: "Ocorreu um erro para obter Ultima KM, por favor tente novamente mais tarde",
        },
        CRLV: {
            title: "CRLV digital",
            request: "O CRLV digital deste veículo não encontra-se disponível. Para solicitá-lo clique abaixo em SOLICITAR.",
            requested: "A sua solicitação foi enviada com sucesso. Como ação imediata em até 48 horas enviaremos uma cópia do CRLV no seu email e em breve ele estará disponível no MyALD Manager.",
            notEligible: "O CRLV digital não forma parte do seu contrato de gestão e por este motivo ele está indisponível. Para inclusão de novos serviços fale com o Gerente Comercial ALD que atende a sua empresa.",
            error: "Ocorreu um erro ao trazer o CRLV",
            requestError: "Ocorreu um erro ao enviar sua solicitação"
        }
    },
    maintenance: {
        titles: [
            /*00*/"Nova manutenção",
            /*01*/"Responsável",
            /*02*/"Informe aqui o condutor ou pessoa que acompanhará a manutenção junto a oficina e ALD",
            /*03*/ 'Abaixo você encontrará o registro de manutenção previamente solicitada. Para solicitar nova manutenção, clique no botão "Solicitar novo serviço".',
            /*04*/ 'Detalhes sobre a pessoa que levará o veículo até a garagem'
        ],
        subtitle: "Aqui você encontra as manutenções solicitadas por este módulo ou pelos condutores via aplicativo. Informações gerenciais estão disponíveis em Relatórios da Frota.",
        table: [
            /*00*/"Contrato",
            /*01*/"Placa",
            /*02*/"Serviço",
            /*03*/"Status",
            /*04*/"Data da Solicitação",
            /*05*/"Estado",
            /*06*/"Cidade",
            /*07*/"Responsável",
            /*08*/"Telefone do Responsável",
            /*09*/"Empresa",
            /*10*/"Estado",
            /*11*/"Estado",
            /*12*/"Placa",
        ],
        buttons: [
            /*00*/"Novo serviço",
            /*01*/"Solicitar serviço",
            /*02*/ "Solicite um novo serviço"
        ],
        filters: [
            /*00*/"Empresa",
            /*01*/"Data de início",
            /*02*/"Data final",
            /*03*/"Filtrar",
            /*04*/"Limpar Filtros"
        ],
        infoMessages: [
            /*00*/"Selecione uma data de início",
            /*01*/"Selecione uma data de término",
            /*02*/"Nenhum serviço foi encontrado com base nos filtros especificados",
            /*03*/"Nenhum serviço encontrado",
            /*04*/"O ingresso atual não tem pontos de controle para mostrar",
        ],
        modal: [
            /*00*/ "Timeline",
            /*01*/ "Ticket SAC",
            /*02*/ "Tipo de serviço"
        ],
        groupTable: {
            headerServiceType: 'Tipo de Serviço',
            headerPlate: 'Placa',
            headerPhone: 'Telefone',
            headerKilometer: 'Quilometragem Atual',
            headerCountrystate: 'Estado',
            headerCity: 'Cidade',
            headerDateOne: 'Data1',
            headerDateTwo: 'Data2',
            headerDateThree: 'Data3',
            headerRegion: 'Região',
            headerArmored: 'Veículo Blindado?',
            headerRoundTrip: 'Leva e Traz?',
            headerDetails: 'Informações adicionais',
            headerResponsalName: 'Quem acompanhará a manutenção?',
            headerResponsalPhone: 'Responsável Telefone',
            headerResponsalPhoneChile: 'Telefone do condutor',
            headerResponsalEmail: 'Email',
            roundTrip: 'Leva e Traz?',
            pickupDir: 'Endereço de Coleta e Devolução',
            armored: 'Veículo Blindado',
            groupService: 'Nova solicitação massiva',
            groupMaintenance: 'Manutenção massiva (várias placas)',
            addRow: 'Adicionar linhas',
            requestService: 'Solicitar serviço',
            selected: 'Selecionado',
            yes: "Sim",
            no: "Não",
            maintenance: 'Manutenção',
            tires: 'Pneus',
            battery: 'Bateria',
            revision: 'Revisão',
            repairs: 'Reparos',
            technicalRevision: 'Revisão Técnica',
            technicomechanicalRevision: 'Revisão Tecnicomecânica',
            placeholderPhoneMexico: "Telefone Fleet Manager",
            placeholderResponsalPhoneMexico: "Telefone Motorista",
            placeholderResponsalPhoneChile: "(56+) telefone de 9 a 11 dígitos",
            PlaceholderResponsalPhonePeru: "Célula do motorista (9 dígitos)",
            placeholderPhoneColombia: "Telefone de 10 dígitos"
        },
        errors: {
            emptyFields: "Um ou mais campos estão vazios",
            emptyDates: "Uma ou mais datas estão vazias",
            emptyDatesColombia: "As duas primeiras datas são necessárias",
            invalidDate: "A data não é válida",
            invalidDateWeekend: "Inválida: Você deve agendar os serviços de 2ª a 6º na placa",
            invalidDateOutSchedule: "Inválida: A hora de serviço não pode ser fora do horário de trabalho na placa",
            invalidDatePass48Hours: "Inválida: As marcações devem ser feitas com 48 horas de antecedência na placa",
            invalidDatePreviousDate: "Inválida: Não pode ser programado antes da data de hoje na placa",
            invalidDateOutScheduleBrasil: "Inválida: O horário de serviço selecionado não é válido, o horário de funcionamento é das 7h até às 17h na placa",
            invalidDateOutScheduleMexico: "Inválida: O horário de serviço selecionado não é válido, o horário de funcionamento é das 8h até às 20h na placa",
            invalidDateOutSchedulePeru: "Inválida: O horário de serviço selecionado não é válido, o horário de funcionamento é das 7h até às 18h na placa",
            invalidDateOutScheduleChile: "Inválida: O horário de serviço selecionado não é válido, o horário de funcionamento é das 8h até às 17h na placa",
            invalidDateOutScheduleColombia: "Inválida: O horário de serviço selecionado não é válido, o horário de funcionamento é das 8h até às 18h na placa",
            invalidDateWeekendColombia: "Inválida: Não é possível agendar aos domingos",
            invalidEmail: "O e-mail da pessoa responsável tem um formato inválido na placa",
            repeatedPlate: "A seguinte placa é repetida",
            rowError: "Erro no envio de dados na placa",
            failConnection: "Ocorreu um erro de conexão, por favor tente novamente mais tarde",
            successConnection: "Todos os dados foram enviados com sucesso",
            successRowSend: "Envio de dados com sucesso na placa",
            repeatedService: "A(s) seguinte(s) placa(s) tem um serviço similar em andamento",
            emptyServiceType: "O campo Tipo de Serviço está vazio na placa",
            emptyPlate: "Foi encontrada uma fila sem placa atribuída",
            emptyPhone: "O campo Telefone está vazio na placa",
            emptyKilometer: "O campo Quilometragem Atual está vazio na placa",
            emptyCountryestate: "O campo Estado está vazio na placa",
            emptyCity: "O campo Cidade está vazio na placa",
            emptyRegion: "O campo Região está vazio na placa",
            emptyDate1: "O campo Data1 está vazio na placa",
            emptyDate2: "O campo Data2 está vazio na placa",
            emptyDate3: "O campo Data3 está vazio na placa",
            emptyDates: "É necessário selecionar pelo menos uma data para na placa",
            emptyArmored: "Indique o campo Veículo Blindado na placa",
            emptyRoundTrip: "Indique o campo Leva e Traz está vazio na placa",
            emptyPickupDir: "O campo Endereço de Coleta e Devolução está vazio na placa",
            emptyResponsalName: "O campo Quem acompanhará a manutenção está vazio na placa",
            emptyResponsalPhone: "O campo Responsável Telefone está vazio na placa",
            emptyResponsalEmail: "O campo Email está vazio na placa",
            limitPhoneChile: "O telefone fornecido não é válido, deve ter no mínimo 9 ou no máximo 11 dígitos na patente",
            limitPhoneRSChile: "O telefone do motorista fornecido não é válido, deve ter no mínimo 9 ou no máximo 11 dígitos na patente",
            limitPhonePeru: "O telefone fornecido não é válido, deve ter no mínimo 9 dígitos na placa",
            limitPhoneRSPeru: "O telefone do motorista fornecido não é válido, deve ter no mínimo 9 dígitos na placa",
            limitPhoneColombia: "O número de telefone fornecido não é válido, deve ter no mínimo 10 caracteres na placa",
            limitPhoneRSColombia: "O número de telefone do responsável fornecido não é válido, deve ter no mínimo 10 caracteres na placa",
            errorBI: "Desculpe, ocorreu um erro no nosso servidor ao carregar o BI, tente novamente mais tarde."
        }
    },
    modalTicketBr: {
        alertMessage: {
            title: "Atenção",
            description: "As datas de sua preferência para agendamento de Manutenção, Revisão periódica ou troca de Pneus não devem ultrapassar 45 dias de antecedência."
        },
        inputs: [
            /*00*/"DDD + telefone do condutor",
            /*01*/"Quilometragem",
            /*02*/"Estado",
            /*03*/"Cidade",
            /*04*/"Região de preferência",
            /*05*/"Data 1",
            /*06*/"Data 2",
            /*07*/"Data 3",
            /*08*/"Veículo blindado?",
            /*09*/"Leva e Traz?",
            /*10*/"Informe o endereço para coleta do veículo em caso de opção pelo serviço Leva e Traz",
            /*11*/"Informe o endereço para devolução do veículo em caso de opção pelo serviço Leva e Traz",
            /*12*/"Informe o endereço para coleta e devolução do veículo em caso de opção pelo serviço Leva e Traz",
            /*13*/"Informações adicionais",
            /*14*/"Nome",
            /*15*/"Email",
            /*16*/"Descreva aqui se o veículo está com algum barulho diferente ou problemas adicionais como ar condicionado e outros...",
            /*17*/"Placa",
            /*18*/"DDD + telefone do responsável"
        ],
        levaETraz: [
            /*00*/"Leva e Traz",
            /*01*/"Não",
            /*02*/"Leva",
            /*03*/"Traz",
            /*04*/"Leva e Traz"
        ],
        errorMessages: [
            /*00*/"Por favor, insira um número de telefone ou celular",
            /*01*/"Por favor, selecione um veículo antes de continuar",
            /*02*/"Por favor insira o nome do responsável por acompanhar o serviço",
            /*03*/"Por favor insira o email do responsável pelo serviço",
            /*04*/"Insira um e-mail válido para o responsável pelo serviço",
            /*05*/"Insira um número de telefone ou celular da pessoa responsável pelo serviço",
            /*06*/"Preencha o campo de quilometragem",
            /*07*/"Selecione um estado para agendar o serviço",
            /*08*/"Selecione uma cidade para agendar o serviço",
            /*09*/"Por favor preencha o campo de informação adicional",
            /*10*/"Indique se o carro tem armadura",
            /*11*/"Selecione se você precisa do serviço de Leva e Traz",
            /*12*/"As datas de atenção não podem ser as mesmas",
            /*13*/"Informe o endereço para coletar o veículo do serviço Leva e traz selecionado acima!",
            /*14*/"Por favor, selecione a região em que você deseja realizar o serviço",
            /*15*/"Você deve agendar os serviços de 2ª a 6ª feira!",
            /*16*/"O horário de serviço selecionado não é válido, o horário de funcionamento é das 7h até às 17h",
            /*17*/"Selecione uma data para agendar o serviço",
            /*18*/"Desculpe, não podemos registrar o serviço solicitado de momento",
            /*19*/"Desculpe, ocorreu um erro em nosso servidor ao registrar o serviço solicitado, tente novamente mais tarde",
            /*20*/"O agendamento deve ser solicitado com 48h de antecedência",
            /*21*/"A data de agendamento não pode ser inferior a data de hoje"
        ],
        successMessages: [
            /*00*/"Sua solicitação foi enviada corretamente. Em breve um colaborador entrará em contato para ajudá-lo!"
        ],
        infoMessages: [
            /*00*/"Nossa equipe avaliará elegibilidade ao produto Leva e traz de acordo ao contrato da sua empresa",
            /*01*/"O contrato selecionado é atribuído a um motorista, adicione as informações que faltam do responsável pelo serviço",
            /*02*/"O contrato é atribuído a mais de um motorista, adicione manualmente os dados do responsável pelo serviço",
            /*03*/"O contrato não é atribuído a nenhum motorista, insira manualmente os dados do responsável pelo serviço"
        ]
    },
    modalTicketGnr: {
        inputs: [
            /*00*/"Placa",
            /*01*/"DDD + telefone do conductor",
            /*02*/"Quilometragem",
            /*03*/"Estado",
            /*04*/"Cidade",
            /*05*/"Informe 1 data",
            /*06*/"Informe 2 data",
            /*07*/"Informações adicionais",
            /*08*/"Nome",
            /*09*/"Email",
            /*10*/"Descreva aqui se o veículo está com algum barulho diferente ou problemas adicionais como ar condicionado e outros...",
            /*11*/"Estado", //Nota: Esta traducción no se debe eliminar es la traducción al PT de Departamento que usan en Colombia y Perú
            /*12*/"Estado", //Nota: Esta traducción no se debe eliminar es la traducción al PT de Regíon que usan en Chile
            /*13*/"Tipo de servició",
            /*14*/ "Telefone corporativo",
            /*15*/ "E-mail corporativo"
        ],
        serviceType: [
            /*00*/"Manutenção Preventiva",
            /*01*/"Manutenção Correctiva"
        ],
        errorMessages: [
            /*00*/"Por favor, insira um número de telefone ou celular",
            /*01*/"Por favor, selecione um veículo antes de continuar",
            /*02*/"Por favor insira un nome del responsable del servicio",
            /*03*/"Por favor insira o email do responsável pelo serviço",
            /*04*/"Insira um e-mail válido para o responsável pelo serviço",
            /*05*/"Insira um número de telefone ou celular da pessoa responsável pelo serviço",
            /*06*/"Preencha o campo de quilometragem",
            /*07*/"Selecione um estado para agendar o serviço",
            /*08*/"Selecione uma cidade para agendar o serviço",
            /*09*/"Por favor preencha o campo de informação adicional",
            /*10*/"As datas de atenção não podem ser as mesmas",
            /*11*/"Você deve agendar os serviços de 2ª a 6ª feira!",
            /*12*/"O horário de serviço selecionado não é válido, o horário de funcionamento é das 8h até às 20h",
            /*13*/"Este veículo já possui um serviço similar em andamento",
            /*14*/"Selecione uma data para agendar o serviço",
            /*15*/"Não é possível agendar serviços aos domingos",
            /*16*/"Lo horário selecionado não é válido. Aos sábados o agendamento deve ser realizado  entre 8h e 14h",
            /*17*/"O horário selecionado não é válido. O agendamento deve ser realizado  entre 8h e 18h",
            /*18*/"Selecione um tipo de serviço",
            /*19*/"O agendamento deve ser solicitado com 48h de antecedência",
            /*20*/"A data de agendamento não pode ser inferior a data de hoje",
            /*21*/"Selecione um estado para agendar o serviço",
            /*22*/"Selecione um estado para agendar o serviço",
            /*23*/"A hora selecionada é inválida. A programação deve ser feita entre 8h30 e 13h00 e das 14h00 às 17h30",
            /*24*/"A hora selecionada é inválida. Aos sábados o agendamento deve ser feito entre 8h30 e 13h."
        ],
        successMessages: [
            /*00*/"Sua solicitação foi enviada corretamente. Em breve um colaborador entrará em contato para ajudá-lo!"
        ],
        infoMessages: [
            /*00*/"O contrato selecionado é atribuído a um motorista, adicione as informações que faltam do responsável pelo serviço",
            /*01*/"O contrato é atribuído a mais de um motorista, adicione manualmente os dados do responsável pelo serviço",
            /*02*/"O contrato não é atribuído a nenhum motorista, insira manualmente os dados do responsável pelo serviço"
        ],
        noContractMessage: "Não há contratos disponíveis"
    },
    checkPointsTimeline: {
        titles: [
            /*00*/ "Ticket aberto",
            /*01*/ "Agendamento",
            /*02*/ "Chegou na Oficina?",
            /*03*/ "Orçamento em elaboração!",
            /*04*/ "Orçamento em aprovação",
            /*05*/ "Previsão de entrega do veículo",
            /*06*/ "Retirou seu veículo?",

            /*07*/ "Check in realizado",
            /*08*/ "Orçamento recebido pela ALD",
            /*09*/ "Orçamento aprovado",
            /*10*/ "Check out realizado!",
            /*11*/ "Orçamento reprovado"
        ],
        generalMessages: [
            /*00*/ "Estamos trabalhando nisso! \n Em breve você receberá as informações \n do seu agendamento",
            /*01*/ "Faça check in e garanta mais agilidade!",
            /*02*/ "A oficina está analisando seu veiculo \n e enviará um orçamento para aprovação",
            /*03*/ "Estamos trabalhando nisso e logo aprovaremos a manutenção do seu veículo!",
            /*04*/ "Entrega prevista ",
            /*05*/ "Faça Check out aqui!",
            /*06*/ "Em breve você receberá o prazo de entrega do seu veículo",
            /*07*/ "Entrega do seu veículo",
            /*08*/ "Em caso de dúvidas ligue para nossa central de Apoio ao Condutor",
            /*09*/ "Ticket cancelado",
            /*10*/ "Clique aqui, faça check in e garanta mais agilidade em todo o processo",
            /*11*/ "\n \n * Antes de buscar seu veículo, ligue e confirme com o fornecedor, pois imprevistos poderão ocorrer durante a realização do serviço.",
            /*12*/ "Clique aqui e faça seu check out!"
        ],
        ticketType: [
            /*00*/ "Manutenção | Revisão periódica",
            /*01*/ "Mantimento preventivo",
            /*02*/ "Manutenção",

            /*03*/ "Manutenção corretiva",
            /*04*/ "Revisão Técnica",

            /*05*/ "Pneus",
            /*06*/ "Pneus",

            /*07*/"Revisão técnica mecânica",

            /*08*/"Reparos",

            /*09*/"Bateria",
        ]
    },
    bireport: {
        indicators: "Indicadores",
        canbus: "Canbus",
        messageSoon: "Muito em breve, em seu portal do Fleet Manager, a ALD disponibilizará esta nova funcionalidade para que você possa visualizar relatórios sobre sua frota. Tudo isso do conforto do seu portal do Fleet Manager"
    },
    aldnet: {
        title: "ALD NET (Faturas)",
        message: "O ALD Net Será aberto em uma nova guia do navegador",
        instrucctions: "O Login e senha para acessar o ALD NET é a mesma que você utilizava para acessar o antigo portal. Caso não se lembre clique em “I forgot my password”",
        button: "IR AO ALD NET"
    },
    alertMessage: {
        message: "A sessão terminou para continuar usando a plataforma é necessário fazer o login novamente"
    },
    PrivacyPolicy: {
        terms: 'Termos e Condições de Uso',
        privacy: 'Política de Privacidade',
        cookies: 'Política de Cookies',
        advice: 'Antes de começar, leia e aceite as nossas Políticas e Termos atuais:',
        accept: 'Eu li e aceito'
    },
    documents: {
        messageSoon: "Muito em breve aqui no seu portal My ALD para gestores de frotas, colocaremos a sua disposição esta nova funcionalidade com a qual você poderá disponibilizar a sua política de frota a todos os condutores de forma rápida e prática!",
        headers: [
            /*00*/ "Tipo",
            /*01*/ "Título",
            /*02*/ "Categoría",
            /*03*/ "Informações de embarque",
            /*04*/ "Notas",
            /*05*/ "Tipo do documento"
        ],
        title: "Meus documentos",
        subtitle: "Histórico do contrato",
        modalTitle: "Carregar documentos",
        buttonTitle: "Carregar documento",
        deleteMessage: "Você tem certeza de que quer apagar o documento?",
        modal: [
            /*00*/ "Nome do documento",
            /*01*/ "Categoria ",
            /*02*/ "Observações"
        ],
        options: [
            /*00*/ "Gerente de frota",
            /*01*/ "Condutor",
            /*02*/ "General"
        ],
        alertMessages: [
            /*00*/ "Nenhum documento encontrado",
            /*01*/ "Documento eliminado com sucesso",
            /*02*/ "Você precisa selecionar um arquivo para carregar",
            /*03*/ "Você precisa selecionar pelo menos um cliente para carregar o documento",
            /*04*/ "A extensão do arquivo é inválida, deve ser .pdf",
            /*05*/ "A extensão do arquivo é inválida, deve ser .xlsx",
            /*06*/ "O nome do arquivo é inválido, não pode conter caracteres especiais",
            /*07*/ "O tamanho do arquivo é muito grande (3MB no máximo)",
            /*08*/ "Nome de arquivo inválido",
        ]
    },
    gloveBox: {
        documentType: "Tipo do documento",
        gloveBoxApp: "Glove Box App (MI AUTO)",
        documentApp: "Documentos App",
        descriptionDocuments: "Aqui você pode publicar documentos oficiais para os condutores de sua frota como políticas de frota, infográficos de processos etc. Os documentos poderão ser visualizados no aplicativo em Políticas e Documentos.",
        descriptionGloveBox: "Aqui ficam os documentos do carro ",
        upload: "Carregar arquivo",
        saving: "Carregando",
        save: "Carregado",
        saveFile: "SALVAR",
        alertMessages: [
            /*01*/ "Houve um erro. Por favor, tente novamente",
            /*02*/ "Carregamento de arquivo concluído com sucesso!",
            /*03*/ "Nenhum contrato encontrado",
            /*04*/ "A extensão do arquivo é inválida, deve ser .pdf",
        ]
    },
    Notification: {
        description: "Aqui você pode publicar avisos gerais, notícias rápidas e eventos para os condutores de sua frota. As notificações poderão ser visualizadas em \"Notificações\" no menu lateral do app do condutor",
        deleteNotificationTitle: "Você tem certeza de que deseja apagar a notificação?",
        deleteNotificationText: "Uma vez que a notificação tenha sido excluída, será necessário entrar em contato com o administrador para recuperá-la novamente.",
        noNotifications: "Nenhuma notificação encontrada momentaneamente",
        deleteNotificationSuccess: "Notificação eliminada com sucesso",
        appendix: "Pré visualização",
        category: "Categoría",
        title: "Titulo",
        date: "Data da publicação",
        createDate: "Data da criação",
        link: "Link adicional",
        newNotification: "Nova notificação",
        addNotification: {
            addNotification: "Criar nova notificação",
            document: "Arquivo PDF",
            title: "Titulo *",
            type: "Tipo *",
            description: "Descrição",
            date: "Data de publicação",
            hourDate: "Hora de publicação",
            link: "Link adicional",
            selectCompany: "Selecione as empresas",
            userType: "Tipo de usuário:",
            userFM: "Gestor de Frota",
            userDriver: "Condutor",
            selectAll: "Selecionar todos",
            accept: "Salvar",
            labelMaxLenght: "Máximo 90 caracteres",
            labelUsers: "Selecione todos ou arraste aqui os usuários que devem receber esta comunicação",
            customerCare: "Assinatura do cliente"
        },
        AlertsMassage: [
            "Houve um erro ao criar sua notificação. Tente Novamente.", // 0
            "Notificação excluída com sucesso", //1
            "Notificação criada com sucesso!", // 2
            "No se encontraron notificaciones creadas", // 3
            "Adicione as informações que faltam para carregar a notificação.", //4
            "Sem notificações no momento", //6


        ],
        FilterLabels: {
            category: "Categoria",
            title: "Título",
            createDate: "Data da criação",
            publishDate: "Data da publicação",
        }
    },
    AldSacTimeLine: {
        titles: [
            /*00*/ "Ticket aberto",
            /*01*/ "Agendamento",
            /*02*/ "Chegou na Oficina?",
            /*03*/ "Orçamento em elaboração!",
            /*04*/ "Orçamento em aprovação",
            /*05*/ "Previsão de entrega do veículo",
            /*06*/ "Retirou seu veículo?",

            /*07*/ "Check in realizado",
            /*08*/ "Orçamento recebido pela ALD",
            /*09*/ "Orçamento aprovado",
            /*10*/ "Check out realizado!",
            /*11*/ "Orçamento reprovado"
        ],
        generalMessages: [
            /*00*/ "Estamos trabalhando nisso! \n Em breve você receberá as informações \n do seu agendamento",
            /*01*/ "Faça check in e garanta mais agilidade!",
            /*02*/ "A oficina está analisando seu veiculo \n e enviará um orçamento para aprovação",
            /*03*/ "Estamos trabalhando nisso e logo aprovaremos a manutenção do seu veículo!",
            /*04*/ "Entrega prevista ",
            /*05*/ "Faça Check out aqui!",
            /*06*/ "Em breve você receberá o prazo de entrega do seu veículo",
            /*07*/ "Entrega do seu veículo",
            /*08*/ "Em caso de dúvidas ligue para nossa central de Apoio ao Condutor",
            /*09*/ "Ticket cancelado",
            /*10*/ "Clique aqui, faça check in e garanta mais agilidade em todo o processo",
            /*11*/ "* Antes de buscar seu veículo, ligue e confirme com o fornecedor, pois imprevistos poderão ocorrer durante a realização do serviço.",
            /*12*/ "Clique aqui e faça seu check out!"
        ],
        alerts: [
            // Alerta para cancelación de servicio
            /*00*/ 'Cancelar',
            /*01*/ 'Deseja realmente cancelar o serviço?',

            // Alerta para reagendar servicio
            /*02*/ 'Reagendar',
            /*03*/ 'Deseja reagendar o serviço?',

            // Alerta para CheckIn
            /*04*/ 'Check in',
            /*05*/ 'Deseja informar à ALD que está com seu veículo na oficina ou concessionária?',
            /*06*/ 'Sim',
            /*07*/ 'Padronizar novamente',
            /*08*/ 'Não',

            // Alerta al cancelar servicio correctamente
            /*09*/ 'Sua solicitação de cancelamento foi enviada com sucesso!',

            // Alerta para error al cancelar servicio
            /*10*/ 'Ocorreu um erro ao tentar cancelar o serviço solicitado. \nTente novamente e se o problema persistir contate a Central de Apoio ao Condutor para solicitar o cancelamento!',

            // Alerta al reagendar servicio correctamente
            /*11*/ "Sua solicitação de reagendamento foi enviada corretamente. \nAssim que nossa equipe agendar o serviço você receberá a confirmação na Timeline do seu ticket. Fique atento!",

            // Alert para error al reagendar servicio
            /*12*/ "Ocorreu um erro ao tentar reagendar o serviço. \nTente novamente e se o problema persistir contate a Central de Apoio ao Condutor.",

            //Check-in/out
            /*13*/ 'Solicitação realizada',

            //Error en la obtención de tickets
            /*14*/ 'Ocorreu um erro ao tentar obter as informações de serviço. \nTente novamente e se o problema persistir contate a Central de Apoio ao Condutor.',

            //Mensajes para checkIn y CheckOut
            /*15*/ "Check In",
            /*16*/ "Deseja confirmar à ALD que deu entrada com seu veículo no fornecedor?",
            /*17*/ "Check Out",
            /*18*/ "Deseja confirmar à ALD que já retirou o seu veículo do fornecedor?",
            /*19*/ "Ocorreu um erro ao confirmar a solicitação, feche o aplicativo e tente novamente."
        ],
        Button: [
            /*00*/ 'Cancelar agendamento?',
            /*01*/ 'Solicitando cancelamento de serviço',
            /*02*/ 'Solicitando reagendar o serviço',
            /*03*/ 'Check In',
            /*04*/ 'Check Out'
        ]
    },
    home: {
        products: [
            /*00*/ "Inteligência",
            /*01*/ "Mobilidade",
            /*02*/ "Performance",
            /*03*/ "Sustentabilidade",
            /*04*/ "Produtos ALD",
            /*05*/ "Saiba mais sobre nossos produtos!",
            /*06*/ "Mais produtos chegando em breve !!!",
        ],
        blog: [
            /*00*/ "Notícias ALD",
            /*01*/ "ALD está aqui para ajudá-lo, venha e confira nosso extenso blog onde você encontrará idéias e soluções de mobilidade.",
            /*02*/ "Clique e saiba mais..."
        ],
        news: [
            /*00*/ "Receba as últimas notícias sobre Mobilidade, para saber mais sobre como ALD pode ajudar você e sua frota a crescer."
        ]
    },
    Months: {
        completos: [
            /*01*/ "Janeiro",
            /*02*/ "Fevereiro",
            /*03*/ "Março",
            /*04*/ "Abril",
            /*05*/ "Maio",
            /*06*/ "Junho",
            /*07*/ "Julho",
            /*08*/ "Agosto",
            /*09*/ "Setembro",
            /*10*/ "Outubro",
            /*11*/ "Novembro",
            /*12*/ "Dezembro"
        ],
        abreviatura: [
            /*01*/ "Jan",
            /*02*/ "Fev",
            /*03*/ "Mar",
            /*04*/ "Abr",
            /*05*/ "Mai",
            /*06*/ "Jun",
            /*07*/ "Jul",
            /*08*/ "Ago",
            /*09*/ "Set",
            /*10*/ "Out",
            /*11*/ "Nov",
            /*12*/ "Dec"
        ]
    },
    ModuleBI: {
        title: 'AVISO',
        Paragraph_1: 'O seu usuário do MyALD ainda está sem as devidas permissões de acesso aos Relatórios da Frota.',
        Paragraph_2: 'Após a liberação do seu acesso ao Portal essa permissão leva 24h para ser habilitada. Se este período já passou pedimos que entre em contato com o ',
        Paragraph_3: 'informando que você não está conseguindo ver os Relatórios da Frota no MyALD Manager.'
    },
    ModuleAgreements: {
        BudgetApproval: 'Aprovação de Orçamento',
        ChooseCustomer: 'Escolha uma empresa para ver os dados',
        DoesntHaveBudget: 'A empresa selecionada não possui novos orçamentos para aprovação.',
        Filters: {
            clean: 'Limpar Filtros',
            clients: 'Empresa',
            plates: 'Placa',
            filters: 'Filtros',
            pedding: 'Pendente',
            approved: 'Aprovado',
            rejected: 'Reprovado',
            cancel: 'Cancelar',
            apply: 'Aplicar',
        },
        table: {
            idSat: 'Nº Orçamento',
            plate: 'Placa',
            model: 'Modelo',
            enterprise: 'Empresa',
            dateService: 'Data do serviço',
            description: 'Descrição',
            price: 'Preço com IVA',
            provider: 'Fornecedor',
            status: 'Status ',
            actions: 'Ações',
            sfTicket: 'Ticket (SF Case)',
        },
        actions: {
            view: 'Ver orçamento',
            comments: 'Comentários',
            history: 'Histórico',
            attachment: 'Ver documento',
        },
        budget: {
            id: 'Nº Orçamento',
            vehicle: 'Veículo',
            plate: 'Placa',
            km: 'KM',
            vin: 'Chassis',
            driver: 'Condutor',
            customer: 'Empresa',
            supplier: 'Fornecedor',
            workshop: 'Oficina',
            quantity: 'Quantidade',
            description: 'Descrição',
            price: 'Preço (com IVA)',
            priority: 'Prioridade',
            rejected: 'Reprovação total',
            partialAproval: 'Autorização parcial',
            fullAproval: 'Autorização completa',
            salesForceCaseNumber: 'Nº Caso SF',
            low: 'Baixa',
            medium: 'Média',
            high: 'Alta',
            parts: 'Peças',
            manoObra: 'Mão de obra',
            typeCost: 'Tipo de custo',
            rebill: 'REFATURA',
            maintenance: 'Manutenção',
        },
        comments: {
            comments: 'Comentários',
            noComments: 'Não possui comentários',
            hasNoComments: 'Este orçamento não possui comentários',
            insertComments: 'Incluir comentário ',
            writeComments: 'Escrever observações aqui',
        },
        alerts: {
            selectItems: 'Selecione pelo menos um item para continuar',
            procesingRequest: 'Estamos processando a sua solicitação. Por gentileza não tente executar a ação novamente.',
            workingNewBudget: 'Estamos gerando um novo orçamento. Quando etiver disponível, você poderá visualizá-lo aqui no portal!',
            selectOneItem: 'Selecione ao menos um item para continuar.',
            rejectedBudget: [
                'Você está reprovando um orçamento por completo.', // 0
                'Deseja continuar?', //1
            ],
            fullApprovalBudget: [
                'Você está realizando a aprovação total do orçamento.', // 0
                '', //1
                'Deseja continuar?' //2
            ],
            partialBudget: [
                'Você está tentando enviar uma aprovação PARCIAL, Enviaremos sua solicitação parcial para a ALD.', // 0
                'Deseja continuar?' // 1
            ],
            itemsRejected: '* Itens REPROVADOS pelo Gestor de Frotas',
            itemsApproved: '* Itens APROVADOS pelo Gestor de Frotas',
            errorFile: "Não foi possível fazer o upload do arquivo, a estrutura do attachment  é inválida",
            emptyAttachment: "Nenhum anexo disponível ainda",
            errorAttachment: 'Ocorreu um erro ao obter o anexo',
        }
    }
}