/**
 * @fileoverview Form para registrar el token de autenticación
 * @version 1.0.0
 * @author Leslie Mosqueda
 * @date 21/05/2024
 * @copyright 2024 Industrias RESSER S.A de C.V
 */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Tooltip } from '@material-ui/core'
import { useHistory } from "react-router-dom";
import swal from 'sweetalert';
//traduction
import { useTranslation } from "react-i18next";
import Rssr from "../../../libs/Rssr";
import { IoIosHelpCircleOutline } from "react-icons/io";
import { IconContext } from "react-icons";
import { MdArrowBackIos, MdRefresh } from 'react-icons/md';
import logo from "assets/img/logo_ALD_black.png";
// Component Styles.
import styles from './styles';
import { makeStyles } from '@material-ui/core/styles';
import InputToken from '../../../components/CustomInput/InputToken';
//axios
import axios from "libs/AxiosHelper";
import { enqueueErrorSnackbar } from 'libs/Snackbar';
import { enqueueSuccessSnackbar } from 'libs/Snackbar';
//redux actions
import {
    showCircularProgress,
    closeCircularProgress,
    loginUser,
    setLoginData,
    addModules,
    addCategories,
    setRememeberUserData,
    setCurrentUrl,
    setShowCookies,
    showSidebarMask,
    hideSidebarMask,
    showNotificationMask
} from 'redux/slice';
import { hideNotificationMask } from 'redux/slice/loadingMask';

import Cookies from "js-cookie";

const useStyles = makeStyles(styles);

const AuthenticationToken = ({ checked, data, returnLogin }) => {
    const history = useHistory();
    const classes = useStyles();
    const [t] = useTranslation('common');
    //Redux
    const dispatch = useDispatch();
    const seenCookies = useSelector((state) => state.configurations.seenCookies);
    const [token, setToken] = useState(0);
    const [disabled, setDisabled] = useState(false);

    /**
  * @title getCategories (Async / Await)
  * @description Función Asíncrona para el GET de categorías, petición para la obtención de las categorías relacionadas co el usuario.
  */

    const getCategories = async () => {
        axios.get("modulecategoriesfmv2")
            .then(async (res) => {
                if (res.data.success == true) {
                    if (res.data.items) {
                        const data = res.data.items.sort(function (a, b) {
                            if (a.order > b.order) {
                                return 1;
                            } else {
                                return -1;
                            }
                        });
                        dispatch(addCategories(data));
                        dispatch(hideSidebarMask());
                        return true;
                    }
                } else {
                    enqueueErrorSnackbar(dispatch, t("login.errors.auth.3"));
                }
            })
            .catch((error) => {
                enqueueErrorSnackbar(dispatch, t("login.errors.auth.3"));
            });
    };

    /**
     * @title getModules (Async / Await)
     * @description Función Asíncrona para el GET de módulos, petición para la obtención de módulos relacionados con los usuario.
     */

    const getModules = async () => {
        dispatch(showSidebarMask());
        axios.get("ModulesFMV2")
            .then(async (res) => {
                if (res.data.success) {
                    dispatch(addModules(res.data.items));
                    return true;
                } else {
                    enqueueErrorSnackbar(dispatch, t("login.errors.auth.3"));
                }
            })
            .catch((error) => {
                enqueueErrorSnackbar(dispatch, t("login.errors.auth.3"));
            });
    };

    /**
     * @title getNotification (Async / Await)
     * @description Función Asíncrona para el GET de Notificaciones, petición para la obtención de Notificaciones relacionados con los usuario.
     */

    const getNotification = async () => {
        dispatch(showNotificationMask());
        axios
            .get("NotificationsCatalog/All?start=0&limit=100")
            .then((response) => {
                if (response.data.success) {
                    dispatch(hideNotificationMask());
                }
            })
            .catch((error) => { });
    };

    const handleSendToken = () => {
        token.toString().length < 6 ? enqueueErrorSnackbar(dispatch, t('login.errors.tokenField')) :
            dispatch(addModules([]));
        dispatch(addCategories([]));
        dispatch(showCircularProgress());
        axios
            .postWR1("TokenLogin", { inputToken: token })
            .then(async (response) => {
                if (await response.data.success === false) {
                    if (response.data.code === 1) {
                        enqueueErrorSnackbar(dispatch, t("login.errors.auth.8"));
                    } if (response.data.code === 2) {
                        enqueueErrorSnackbar(dispatch, t("login.errors.auth.9"));
                    }
                } else {
                    if (data.data.UserRole !== 15) {
                        Cookies.set('userData', JSON.stringify(data.userData));
                        localStorage.setItem("userData", JSON.stringify(data.userData));
                        let scope;
                        switch (data.data.ApiId) {
                            case 3:
                                scope = "MyAldFMAPI";
                                dispatch(setCurrentUrl(process.env.REACT_APP_API_FM));
                                break;
                            case 4:
                                scope = "MyAldSupplierAPI";
                                dispatch(setCurrentUrl(process.env.REACT_APP_API_SUPPLIER));
                                break;
                            default:
                                scope = "MyAldAPI";
                                dispatch(setCurrentUrl(process.env.REACT_APP_API_SERVER));
                                break;
                        }
                        await axios.getToken(data.userData, data.data.ApiId);
                        await getCategories();
                        await getModules();
                        await getNotification();
                        if (checked) {
                            dispatch(setRememeberUserData(true));
                        }
                        dispatch(
                            setLoginData({
                                regionId: data.data.RegionId,
                                userId: data.data.UserId,
                                userRole: data.data.UserRole,
                                SignedPolicies: data.data.SignedPolicies,
                            })
                        );
                        localStorage.setItem("NewUserAccount", data.data.Email);

                        dispatch(loginUser());
                        enqueueSuccessSnackbar(dispatch, t("login.correct"));
                        dispatch(closeCircularProgress());
                        if (!seenCookies) {
                            dispatch(setShowCookies(true));
                        }

                        if (data.data.UserRole === 27) {
                            history.push("/admin/aldsat");
                        } else {
                            history.push("/admin/home");
                        }
                    }
                }
            })
            .catch((_error) => {
                enqueueErrorSnackbar(dispatch, t("login.errors.auth.0"));
            })
            .finally(() => {
                dispatch(closeCircularProgress());
            });
    }

    const resendToken = () => {
        dispatch(showCircularProgress());
        axios
            .getWR1("TokenLogin")
            .then((response) => {
                if (response.data.success === false) {
                    enqueueErrorSnackbar(dispatch, t("login.errors.auth.7"));
                } else {
                    enqueueSuccessSnackbar(dispatch, t("login.tokenSuccess"));
                }
            })
            .catch((_error) => {
                enqueueErrorSnackbar(dispatch, t("login.errors.auth.1"));
            })
            .finally(() => {
                dispatch(closeCircularProgress());
            });
    }

    useEffect(() => {
        resendToken();
    }, [])

    const handleHelpToken = () => {
        dispatch(showCircularProgress());
        setDisabled(true);
        axios
            .get("HelpTokenAuthRequest")
            .then((response) => {
                if (response.data.Success === true) {
                    swal(t('login.confirmHelpToken'), {
                        buttons: {
                            ok: t('generalText.confirm')
                        },
                    });
                } else {
                    enqueueErrorSnackbar(dispatch, t("login.errors.auth.4"));
                }
            })
            .catch((_error) => {
                enqueueErrorSnackbar(dispatch, t("login.errors.auth.1"));
            })
            .finally(() => {
                dispatch(closeCircularProgress());
                setDisabled(false);
            });
    }

    return (
        <Grid container direction="column" className={classes.tokenContainer} >
            <Grid container alignItems='center' justifyContent="space-between">
                <Grid item xs={10}>
                    <label className={classes.labelTokenStyle}>
                        {t('login.tokenInfo')}
                        {" " + Rssr.formatProtectedEmail(data.data.Email)}
                    </label>
                </Grid>
                <Grid item >
                    <Tooltip title={t('login.tokenHelp')} arrow
                        classes={{ tooltip: classes.tooltip }}>
                        <div>
                            <IconContext.Provider
                                value={{
                                    size: "25px",
                                    className: "react-icons",
                                    style: { cursor: 'pointer', }
                                }}
                            >
                                <IoIosHelpCircleOutline />
                            </IconContext.Provider>
                        </div>
                    </Tooltip>
                </Grid>

            </Grid>
            <Grid container item justifyContent='center' style={{ marginTop: '10px' }}>
                <InputToken setToken={setToken} />
            </Grid>

            <Grid container justifyContent='center' >
                <a onClick={() => { resendToken() }} className={classes.resendToken}>
                    <Grid container item justifyContent='center' alignItems='center'>
                        <Grid item>
                            <IconContext.Provider
                                value={{
                                    size: "12px",
                                    className: "react-icons",
                                    style: {
                                        marginRight: "2px",
                                        marginTop: "9px"
                                    }
                                }}
                            >
                                <MdRefresh />
                            </IconContext.Provider>
                        </Grid>
                        <Grid item>
                            {t('login.resendToken')}
                        </Grid>
                    </Grid>
                </a>
            </Grid>
            <Grid container item justifyContent='center' className='buttonLogin'>
                <button
                    type={'button'}
                    onClick={() => { handleSendToken() }}
                    className={classes.tokenButton}>
                    {t('login.confirm')}
                </button>
            </Grid>
            <Grid container item justifyContent='center'>
                <label className={classes.labelTokenStyle}>
                    {t('login.receivedToken')}
                </label>
            </Grid>
            <Grid container item justifyContent='center'>
                <a onClick={() => { handleHelpToken() }} className={disabled ? classes.helpStyleDisabled : classes.helpStyle}>
                    {t('login.help')}
                </a>
            </Grid>
            <div className={classes.logo}>
                <img src={logo} alt="logo" className={classes.logoImg} />
            </div>
            <Grid container item justifyContent='center' >

                <a onClick={() => { returnLogin() }} className={classes.returnInicio}>
                    <Grid container item justifyContent='center' alignItems='center'>
                        <Grid item>
                            <IconContext.Provider
                                value={{
                                    size: "12px",
                                    className: "react-icons",
                                    style: {
                                        marginRight: "2px",
                                        marginTop: "9px"
                                    }
                                }}
                            >
                                <MdArrowBackIos />
                            </IconContext.Provider>
                        </Grid>
                        <Grid item>
                            {t('login.signIn')}
                        </Grid>
                    </Grid>
                </a>

            </Grid>
        </Grid>
    )
}

export default AuthenticationToken