export default{
    generalText: {
        loadingSidebar: "Cargando módulos",
        welcome: "Bienvenido",
        header: "EXPERTOS EN MOVILIDAD, GESTIÓN Y ARRENDAMIENTO DE FLOTA",
        skip: "Omitir",
        next: "Siguiente",
        accept: "Aceptar",
        cancel: "Cancelar",
        call: "Llamar",
        send: "Enviar",
        confirm: "Confirmar",
        type: "Tipo",
        goToModule: "ir al módulo",
        soon: "Próximamente",
        continue: "Continuar",
        error: "Error",
        advice: "Aviso",
        correct: "Correcto",
        return: "Regresar",
        other: "Otro",
        delete: "Eliminar",
        yes: "Si",
        no: "No",
        na: "N/A",
        edit: "Actualizar",
        gender: "Sexo",
        telephone: "Teléfono",
        cellphone: "Celular",
        zipCode: "Código Postal",
        help: "Ayuda (Asistencia 24h)",
        helpInfoTitle: "Atención",
        helpInfoBody: "Este teléfono solo debe usarse para asistencia las 24hrs y llamadas en caso de choque, averia o accidente.",
        novelties: "Novedades",
        vehicle: "Vehículo",
        genders: ["Hombre", "Mujer"],
        km: "Actualizar Kilometraje",
        bad: "Malo",
        excellent: "Excelente",
        phonePlaceHolderBra: "DDD + Teléfono",
        phonePlaceHolderMx: "Teléfono",
        searchPlaceHolder: "Búsqueda",
        update: "Actualizar",
        register: "Solicitar registro",
        clic: "Haga clic aquí",
        helpButtonPremium: "Atención Premium",
        updateButton: "Actualizar",
        updateMessage: "!Actualización disponible¡ \n Actualiza a la nueva versión de My ALD para que puedas disfrutar de todas las mejoras y cambios que ALD Latam pone a tu disposición \n (La actualización es necesaria para utilizar My ALD)",
        timeLineAvaliable: "¡Pronto tendrá sus solicitudes devueltas aquí! \n En caso de dudas, llame a nuestro Centro de asistencia al conductor.",
        corregir: "Corregir",
        forgotPassword: "Olvidé mi contraseña",
        active: "Activo",
        finalized: "Vencido",
        logout: "Cerrar sesión",
        at: ' a las ',
        aldAutomotive: "ALD Automotive",
        download: 'Descargar',
        request: "SOLICITAR"
    },
    NavBar:{
        notifications: "Notificaciones",
        language: "Idioma",
        profile: "Perfil",
        settings: "Configuración",
        logout: "Logout"
    },
    AcceptCookies: {
        advice:         "AVISO",
        content:        'Antes de comenzar, lea y acepte nuestras políticas y términos vigentes:',
        return:         "Regresar",
        continue:       "Continuar"
    },
    resetSenha: {
        title:          "¡Atención!",
        expiredToken_1: "Su contraseña temporal tiene una duración de 48 horas y éste tiempo ya expiró.",
        expiredToken_2: "Por favor, responda el email del cual recibió ésta contraseña informando que necesita de una nueva pues la actual se encuentra vencida.",
        titleSuccess:    "¡Exito!",
        titleWarning: "¡Aviso!",
        succesToken:    "¡Su contraseña fue actualizada con éxito!",
        continue:       "OK",
        formFieldsMessages: {
            requiredEmail: "El campo de email es requerido",
            errorEmail: "El correo ingresado no es valido",
            shortPass: "La contraseña debe contener un mínimo de 8 y un máximo de 15 caracteres",
            longPass: "La contraseña ingresada es muy larga",
            fieldRequired: "El campo es requerido",
            samePass: "Las contraseñas deben de ser iguales",
            passwordCriteria: "La contraseña debe de contener al menos una letra minúscula, una letra mayúscula, un número, un carácter especial (!@#$%^&*) y no contar con espacios"
        },
    },
    login: {
        title: "¡Hola, estamos felices de verte aquí!",
        userPlaceholder: "Ingrese con su nombre de usuario",
        userInput: "Usuario",
        passwordPlaceholder: "Ingrese su contraseña",
        passwordInput: "Contraseña",
        passwordInput_2: "Nueva Contraseña",
        rememeberSession: "Recordarme",
        rememberPassword: "Recuperar contraseña",
        signIn: "Iniciar sesión",
        correct: "Autenticación correcta",
        infoMessages: "Por motivos de seguridad, es necesario que realice el cambio de su contraseña.",
        passwordLabel_2: "Escriba una nueva contraseña",
        passwordLabel_3: "Confirme su nueva contraseña",
        continue: "CONTINUAR",
        tokenInfo: "Escribe el TOKEN de 6 dígitos que hemos enviado a tu correo ",
        tokenHelp: "Si no ha recibido el token, recomendamos que revise la bandeja de spam.",
        resendToken: "Reenviar TOKEN",
        confirm: "Confirmar",
        receivedToken: "¿No has recibido el TOKEN?",
        help:" ¿Necesita ayuda?",
        tokenSuccess: "El token a sido enviado por correo, revisa tu bandeja.",
        confirmHelpToken: "Tu solicitud de ayuda fue enviada de forma correcta, nuestro de equipo de soporte se pondrá en contacto para apoyarte con el acceso a tu cuenta.",
        errors: {
            auth: [
                "Error de autentificación",
                "Error de red",
                "Tu cuenta no contiene unidades asignadas, \n para mayor información contacte a su administración de flota",
                "Ocurrio un error al cargar tu información, intenta de nuevo mas tarde",
                "Hemos encontrado un problema con tu usuario. Por favor ponte en contacto con tu Gestor Operacional de Clientes de ALD para obtener soporte",
                "Usuario no detectado en My ALD Manager, por favor solicite su acceso con su Gerente de Cuentas.",
                "Está intentando iniciar sesión con un usuario de tipo Conductor. Esta aplicación está destinada exclusivamente a los gestores de flotas. Utilice la aplicación móvil (My ALD) disponible en la tienda virtual de su teléfono móvil.",
                "Ocurrió un error al enviar el token",
                "El token es invalido",
                "El token ya expiro, favor de generar uno nuevo",
            ],
            fields: "Los campos no pueden estar vacíos",
            userField: "El campo de usuario no puede estar vacío",
            passField: "El campo de la contraseña no puede estar vacío",
            tokenField: "Favor de completar el Token antes de confirmar",
        }
    },
    categoriesNames: [
        /*00*/ "Mi Flota",
        /*01*/ "Mis Reportes",
        /*02*/ "Mis servicios",
        /*03*/ "Mis conductores",
        /*04*/ "MyALD Proveedores",
    ],
    dashboardNames: [
        /*00*/ "ALD Net",
        /*01*/ "IFRS16",
        /*02*/ "Mantenimientos",
        /*03*/ "Actualización de datos",
        /*04*/ "ALD SAT",
        /*05*/ "Perfil",
        /*06*/ "ALDNET",
        /*07*/ "Reporte BI",
        /*08*/ "Mis Documentos",
        /*09*/ "Home",
        /*10*/ "Notificaciones"
    ],
    profileConfiguration: {
        profile: {
            title: "Perfil",
            message: "Este campo solo modificara la dirección de correo a donde llegaran los mensajes.",
            inputs: {
                name: "Nombre",
                email: "Email",
                cellphone: "Nº de Celular",
                birthdate: "Fecha de nacimiento",
                rfc: "RFC",
                postalCode: "Código Postal",
                adress: "Dirección"
            },
            success: [
                /*00*/ "Imagen cargada correctamente",
                /*01*/ "Datos actualizados correctamente"
            ],
            errors: [
                /*00*/ "Ocurrió un error al cargar la imagen",
                /*01*/ "Ocurrió un error al actualizar los datos",
                /*02*/ "El correo brindado no es válido.",
                /*03*/ "El usuario o email proporcionados no existen",
                /*04*/ "El RFC ingresado no es valido"
            ]
        },
        account: {
            title: "Configuración de la cuenta",
            textDescription: "Ingresa tu nueva contraseña, recuerda que deben contener al menos una mayúscula y un número.",
            currentPassword: "Contraseña actual",
            password: "Ingresar contraseña",
            confirmPassword: "Confirmar contraseña",
            selectLanguage: "Seleccione el idioma de su preferencia",
            forgotPassMessage: "Ingrese su nombre de usuario",
            languages: [
                {value: "es", text:"Español"},
                {value: "en", text:"Inglés"},
                {value: "pt", text:"Portugués"},
                {value: "fr", text:"Francés"}
            ],
            success: [
                /*00*/ "Contraseña actualizada con éxito",
                /*01*/ "Cambio de idioma exitoso",
                /*03*/ "Envió de correo para recuperación de contraseña enviado correctamente"
            ],
            errors: [
                /*00*/ "Ocurrió un error al actualizar la contraseña",
                /*01*/ "Ocurrió un error al cambiar el idioma",
                /*02*/ "El usuario o correo de recuperación no puede estar vacío",
                /*03*/ "La contraseña no puede contener más de 10 caracteres",
                /*04*/ "La contraseña no puede contener caracteres especiales"
            ]
        },
        portal:{
            title: "Configuración de portal",
            lateralMenu: "Menú lateral fijo",
            toolTip: "Si está activo: el menu lateral de la izquierda estará siempre visible",
        },
        alerts: {
            title: "Configuración de alertas",
            textDescription: "Activa y desactiva las notificaciones que desees también puedes recibirlas a través de tu correo electrónico.",
            checkbox: "Recibir también vía correo electrónico.",
            slider1: "Servicios Finalizados",
            slider2: "Delivery",
            slider3: "Documentos",
            success: [
                /*00*/ "Alertas configuradas exitosamente",
                /*01*/ "¡Su contraseña fue actualizada con éxito!",
            ],
            errors: [
                /*00*/ "Ocurrió un error al configurar las alertas",
                /*01*/ "El tiempo para reestablecer su contraseña usando éste link expiró. Regrese a My ALD y solicite un nuevo link desde Olvidé mi Contraseña. Atención: cada link expira en 30 minutos después de su envío!",
                /*02*/ "No pudo realizarse el cambio de contraseña",
                /*03*/ "Su contraseña debe ser diferente de las últimas 5.",
            ]
        },
    },
    header: {
        placeholderInput: "Vehículo, ID, Cliente ...",
        search: "Buscar",
    },
    ifrs16: {
        types: [
            /*00*/ "IFRS16 Main Report",
            /*01*/ "IFRS16 Event Report"
        ],
        inputs: [
            /*00*/ "Informes",
            /*01*/ "Fecha de inicio",
            /*02*/ "Fecha de corte",
            /*03*/ "Fecha final"
        ],
        sendMessage: "Enviar informe por email",
        success: [
            /*00*/ "Reporte enviado correctamete"
        ],
        errors: [
            /*00*/ "Ocurrió un error al enviar el reporte"
        ]
    },
    contracts: {
        downloadButton: "Descargar archivo de actualización",
        uploadButton: "Subir archivo de actualización",
        titleSoon: "Próximamente",
        subtitle: 'En este módulo podrá visualizar y gestionar la actualización de datos de sus vehículos.',
        messageSoon: "Muy pronto en tu portal Fleet Manager, ALD pondrá a tu disposición esta nueva funcionalidad donde podrás solicitar la actualización de información, baja  y alta de nuevos contratos. Todo esto desde la comodidad de tu portal Fleet Manager",
        slogan: "Espéralo muy pronto, la movilidad está cambiando....",
        uploadConfirmation: 'Estas seguro de subir el siguiente archivo: ',
        filters: {
            title: "Filtros",
            contract: "Contrato",
            client: "Cliente",
            date: "Fecha",
            plates: "Placas",
            button: "Filtrar",
            noSerie: "Nº de serie",
            status: "Estatus",
            clean: "Limpiar filtos",
            select: "Seleccionar"
        },
        tableColumns: {
            Brand: "Marca",
            Client: "Cliente",
            ClientNumber: "Nº de cliente",
            ContractId: "ID de contrato",
            ContractNumber: "Nº de contrato",
            ContractStatus: "Estatus",
            ContractStatusColombia: "Estatus Contrato",
            ContractStatusString: "",
            ContractType: "Tipo de contrato",
            ContractedMonths: "Meses contratados",
            CostCenter: "Centro de costo",
            Description: "Descripción",
            DescriptionUO: "Descripción UO",
            DirectionArea: "Área",
            EndDate: "Fecha final prevista",
            FleetGroup: "Grupo",
            FuelConsumption: "Consumo de gasolina",
            KMContracted: "KM contratados",
            KMDateUpdate: "Fecha de actualización de KM",
            KMStart: "Ultimo KM",
            KMUpdate: "Actualizción de KM",
            LicensePlate: "Placas",
            Management: "Mantenimiento",
            Model: "Modelo",
            NoEco: "Nº Eco",
            PriceOption: "Opción de precio",
            PriceWithOption: "Precio con opción",
            Register: "Registro",
            SellPrice: "Precio de venta",
            SerialNumber: "NºSerie (VIN/Chassis)",
            StartDate: "Fecha inicial de contrato",
            Tyre: "Tyre",
            UserName: "Nombre de Usuario",
            VP: "VP",
            VehiclePrice: "Precio de vehículo",
            VehicleType: "Tipo de vehículo",
            VehicleTypeDescription: "Descripción del tipo de vehículo",
            id: "ID",
            Active: "Activo",
            Inactive: "Vencido",
            DateNotRegistered: "Fecha No Registrada",
            DateNotRegisteredKM: "No se ha hecho actualización",
            CRLV: "CRLV",
        },
        success: [
            /*00*/ "Archivo subido correctamente"
        ],
        errors: [
            /*00*/ "Ocurrió un error al subir el archivo de actualización",
            /*01*/ "Ocurrió un error al traer los contratos"
        ],
        updateKM:{
            updateKM: "Actualizar Kilometraje",
            buttonUpdateKM: "Enviar Actualización de Kilometraje",
            currentKM: "Kilometraje Actual",
            newKM: "Kilometraje Nuevo",
            placeholderNewKM: "Ingrese Kilometraje Nuevo",
            continue: "¿Desea Continuar?",
            alertKMBelow: "Las siguientes placas poseen un kilometraje nuevo menor al actual",
            alertKMBelowChile: "Las siguientes patentes poseen un kilometraje nuevo menor al actual",
            alertKMGreater: "Las siguientes placas poseen un kilometraje mayor a 450000",
            alertKMGreaterChile: "Las siguientes placas poseen un kilometraje mayor a 450000",
            alertKMSuccess: "La información ha sido enviada correctamente a nuestro equipo y será revisada y actualizada en el sistema lo antes posible. De haber algún problema con la actualización nos estaremos contactando con usted. ¡Muchas gracias!",
        },
        updateKMerrors: {
            emptyPlate: "Existe una fila sin placa asignada",
            emptyCurrentKM: "El kilometraje actual está vacío en la placa",
            emptyCurrentKMHint: "Este se obtiene al seleccionarla de la lista",
            emptyNewKM: "El kilometraje nuevo está vacío en la placa",
            invalidKMZero: "El kilometraje nuevo no puede ser cero en la placa",
            invalidKMEqual: "El kilometraje nuevo tiene que ser diferente al actual en la placa",
            failConnectionGetKM: "Un error ha ocurrido para obtener el kilometraje actual, por favor intente más tarde",
            emptyPlateChile: "Existe una fila sin patente asignada",
            emptyCurrentKMChile: "El kilometraje actual está vacío en la patente",
            emptyNewKMChile: "El kilometraje nuevo está vacío en la patente",
            invalidKMZeroChile: "El kilometraje no puede ser cero en la patente",
            invalidKMEqualChile: "El kilometraje nuevo tiene que ser diferente al actual en la patente",
        },
        CRLV: {
            title: "CRLV digital",
            request: "El CRLV digital de este vehículo no se encuentra disponible. Para solicitarlo clic abajo en SOLICITAR.",
            requested: "Su solicitud fue enviada con éxito! Como acción inmediata hasta 48 horas te enviaremos la cópia de CRLV por correo y luego él estará disponible en MyALD Manager.",
            notEligible: "El Documento digital de su vehículo (CRLV) no forma parte de los productos contratados por su empresa. Para incluir nuevos servicios consulte a su Gerente de Cuentas ALD.",
            error: "Ocurrió un error al traer el CRLV",
            requestError: "Ocurrió un error al enviar la solicitud"
        }
    },
    maintenance: {
        titles:[
            /*00*/"Nuevo mantenimiento",
            /*01*/"Responsable",
            /*02*/"Informe aquí al conductor o persona que acompañará el mantenimiento con el taller y ALD",
            /*03*/ 'A continuación encontrará el registro de los mantenimientos solicitados anteriormente. Para solicitar nuevo mantenimiento haga clic en el botón "Solicitar nuevo servicio"',
            /*04*/ 'Datos de la persona que llevará el vehículo al taller'
        ],
        subtitle: "Encuentre aquí las solicitudes de  servicio realizadas por este módulo o por los conductores a través de la aplicación móvil. Información de gestión están disponibles en Reporte BI",
        table:[
            /*00*/"Nº Contrato",
            /*01*/"Placa",
            /*02*/"Servicio",
            /*03*/"Estatus",
            /*04*/"Fecha de solicitud",
            /*05*/"Estado",
            /*06*/"Ciudad",
            /*07*/"Responsable",
            /*08*/"Teléfono de responsable",
            /*09*/"Cliente",
            /*10*/"Departamento",
            /*11*/"Regíon",
            /*12*/"Patente"
        ],
        buttons:[
            /*00*/"Nuevo servicio",
            /*01*/"Solicitar servicio",
            /*02*/ "Solicitar nuevo servicio"
        ],
        filters: [
            /*00*/"Cliente",
            /*01*/"Fecha Inicial",
            /*02*/"Fecha Final",
            /*03*/"Filtrar",
            /*04*/"Limpiar filtros"
        ],
        infoMessages: [
            /*00*/"Seleccione una fecha inicial",
            /*01*/"Seleccione una fecha final",
            /*02*/"No se encontraron servicios en base a los filtros especificados",
            /*03*/"No se encontraron servicios para mostrar",
            /*04*/"El ticket actual no cuenta con puntos de control por mostrar",
        ],
        modal: [
            /*00*/ "Timeline",
            /*01*/ "Ticket SAC",
            /*02*/ "Servicio"
        ],
        groupTable: {
            headerServiceType: 'Tipo de Servicio',
            headerPlate: 'Placa',
            headerPhone: 'Teléfono',
            headerKilometer: 'Kilometraje',
            headerCountrystate: 'Estado',
            headerCity: 'Ciudad',
            headerDateOne: 'Fecha1',
            headerDateTwo: 'Fecha2',
            headerDateThree: 'Fecha3',
            headerRegion: 'Region',
            headerArmored: 'Blindado',
            headerRoundTrip: 'Ida y Vuelta',
            headerDetails: 'Información adicional',
            headerResponsalName: 'Nombre Responsable',
            headerResponsalPhone: 'Teléfono Responsable',
            headerResponsalPhoneChile:'Teléfono conductor',
            headerResponsalEmail: 'Email Responsable',
            plateChile: 'Patente',
            roundTrip: 'Ida y Vuelta',
            pickupDir: 'Dirección de Recolección',
            armored: 'Blindado',
            groupService: 'Nuevo Servicio en Masa',
            groupServiceMexico: 'Nueva Solicitud Masiva',
            groupServiceColombia: 'Solicitud de Servicio Masivo',
            groupServicePeru: 'Nuevo(s) Servicio(s)',
            groupMaintenance: 'Mantenimiento en grupo',
            addRow: 'Agregar fila',
            requestService: 'Solicitar Servicio',
            selected: 'Seleccionado',
            yes: 'Si',
            no: 'No',
            maintenance: 'Mantenimiento',
            tires: 'Neumáticos',
            tiresColombia: 'Llantas',
            battery: 'Bateria',
            revision: 'Revisión',
            repairs: 'Reparaciones',
            technicalRevision: 'Revisión Técnica',
            technicomechanicalRevision: 'Revisión Tecnicomecánica',
            countryestateMexico: "Estado",
            cityMexico: "Ciudad",
            regionMexico: "Municipio",
            countryestateChile: "Región",
            cityChile: "Ciudad",
            regionChile: "Comuna",
            countryestateColombia: "Departamento",
            cityColombia: "Ciudad",
            regionColombia: "Municipio",
            countryestatePeru: "Departamento",
            cityPeru: "Provincia",
            regionPeru: "Distrito",
            placeholderPhoneMexico: "Teléfono Fleet Manager",
            placeholderResponsalPhoneMexico: "Teléfono Conductor",
            placeholderDetailsMexicoPreventive: "Indica que servicio de kilometraje realizarán (10,000, 25,000, etc.) o si es servicio de verificación.",
            placeholderDetailsMexicoCorrective: "Describa la falla, ruido o problema que presenta su vehículo",
            placeholderResponsalPhoneChile: "(56+) Teléfono de 9 a 11 dígitos",
            PlaceholderResponsalPhonePeru: "Celular conductor (9 dígitos)",
            placeholderPhoneColombia: "Teléfono de 10 dígitos"
        },
        errors: {
            emptyFields: "Uno o más campos están vacíos",
            emptyDates: "Una o más fechas están vacías",
            emptyDatesColombia: "Se necesitan al menos las 2 primeras fechas",
            invalidDate: "No es valida la fecha",
            invalidDateWeekend: "Invalida: No es posible agendar servicios los fines de semana en la placa",
            invalidDateOutSchedule: "Invalida: La hora de servicio no puede estar fuera del horario de atención en la placa",
            invalidDatePass48Hours: "Invalida: La cita debe agendarse con 48 horas de anticipación en la placa",
            invalidDatePreviousDate: "Invalida: No se puede agendar anterior a la fecha de hoy en la placa",
            invalidDateOutScheduleBrasil: "Invalida: La hora de servicio seleccionada no es valida, el horario de atención es de 7h a 17h en la placa",
            invalidDateOutScheduleMexico: "Invalida: La hora de servicio seleccionada no es valida, el horario de atención es de 8h a 20h en la placa",
            invalidDateOutSchedulePeru: "Invalida: La hora de servicio seleccionada no es valida, el horario de atención es de 7h a 18h en la placa",
            invalidDateOutScheduleChile: "Invalida: La hora de servicio seleccionada no es valida, el horario de atención es de 8h a 17h en la placa",
            invalidDateOutScheduleColombia: "Invalida: La hora de servicio seleccionada no es valida, el horario de atención es de 8h a 18h en la placa",
            invalidDateWeekendColombia: "Invalida: No es posible agendar servicios en Domingo en la placa",
            invalidEmail: "El email del responsable tiene un formato invalido en la placa",
            repeatedPlate: "Se repite la placa",
            rowError: "Error al mandar los datos en la placa",
            failConnection: "Ocurrió un error en la conexión, por favor intentelo de nuevo más tarde",
            successConnection: "Todos los datos se han enviado exitosamente",
            successRowSend: "La información se ha enviado exitosamente en la placa",
            repeatedService: "La(s) siguiente(s) placa(s) ya cuenta con un servicio similar en proceso",
            emptyServiceType: "El campo Tipo de Servicio está vacío en la placa",
            emptyPlate: "Existe una fila sin placa asignada",
            emptyPhone: "El campo Teléfono está vacío en la placa",
            emptyKilometer: "El campo Kilometraje está vacío en la placa",
            emptyCountryestate: "El campo Estado está vacío en la placa",
            emptyCity: "El campo Ciudad está vacío en la placa",
            emptyRegion: "El campo Region está vacío en la placa",
            emptyDate1: "El campo Fecha1 está vacío en la placa",
            emptyDate2: "El campo Fecha2 está vacío en la placa",
            emptyDate3: "El campo Fecha3 está vacío en la placa",
            emptyDates: "Es necesario seleccionar por lo menos una fecha para la patente",
            emptyArmored: "Indique si cuenta con blindaje la placa",
            emptyRoundTrip: "Indique si se requiere el servicio Ida y Vuelta para la placa",
            emptyPickupDir: "El campo Dirección de Recolección está vacío en la placa",
            emptyResponsalName: "El campo Nombre Responsable está vacío en la placa",
            emptyResponsalPhone: "El campo Teléfono Responsable está vacío en la placa",
            emptyResponsalEmail: "El campo Email Responsable está vacío en la placa",
            emptyCountryestateMexico: "El campo Estado está vacío en la placa",
            emptyCityMexico: "El campo Ciudad está vacío en la placa",
            emptyRegionMexico: "El campo Municipio está vacío en la placa",
            repeatedPlateChile: "Se repite la patente",
            emptyServiceTypeChile: "El campo Tipo de Servicio está vacío en la patente",
            emptyPlateChile: "Existe una fila sin patente asignada",
            emptyPhoneChile: "El campo Teléfono está vacío en la patente",
            limitPhoneChile: "El teléfono brindado no es valido este debe tener un minimo de 9 o un maximo de 11 digitos en la patente",
            limitPhoneRSChile: "El teléfono cónductor brindado no es valido este debe tener un minimo de 9 o un maximo de 11 digitos en la patente",
            limitPhonePeru: "El teléfono brindado no es valido este debe tener un minimo de 9 digitos en la placa",
            limitPhoneRSPeru: "El teléfono cónductor brindado no es valido este debe tener un minimo de 9 digitos en la placa",
            limitPhoneColombia: "El teléfono brindado no es valido este debe tener un minimo de 10 caracteres en la placa",
            limitPhoneRSColombia: "El teléfono responsable brindado no es valido este debe tener un minimo de 10 caracteres en la placa",
            emptyKilometerChile: "El campo Kilometraje está vacío en la patente",
            emptyCountryestateColombia: "El campo Departamento está vacío en la placa",
            emptyCityColombia: "El campo Ciudad está vacío en la placa",
            emptyRegionColombia: "El campo Municipio está vacío en la placa",
            emptyCountryestatePeru: "El campo Departamento está vacío en la placa",
            emptyCityPeru: "El campo Provincia está vacío en la placa",
            emptyRegionPeru: "El campo Distrito está vacío en la placa",
            emptyCountryestateChile: "El campo Región está vacío en la patente",
            emptyCityChile: "El campo Ciudad está vacío en la patente",
            emptyRegionChile: "El campo Comuna está vacío en la patente",
            emptyDate1Chile: "El campo Fecha1 está vacío en la patente",
            emptyDate2Chile: "El campo Fecha2 está vacío en la patente",
            emptyDate3Chile: "El campo Fecha3 está vacío en la patente",
            emptyArmoredChile: "Indique si cuenta con blindaje la patente",
            emptyRoundTripChile: "Indique si se requiere el servicio Ida y Vuelta para la patente",
            emptyPickupDirChile: "El campo Dirección de Recolección está vacío en la patente",
            emptyResponsalNameChile: "El campo Nombre Responsable está vacío en la patente",
            emptyResponsalPhoneChile: "El campo Teléfono Conductor está vacío en la patente",
            emptyResponsalPhonePeru: "El campo Teléfono Conductor está vacío en la placa",
            emptyResponsalEmailChile: "El campo Email Responsable está vacío en la patente",
            invalidDatePass48HoursChile: "Invalida: La cita debe agendarse con 48 horas de anticipación en la patente",
            invalidDatePreviousDateChile: "Invalida: No se puede agendar anterior a la fecha de hoy en la patente",
            invalidDateWeekendChile: "Invalida: No es posible agendar servicios los fines de semana en la patente",
            errorBI: "Lo sentimos ocurrió un error en nuestro servidor al momento de cargar el BI, intenta de nuevo más tarde"
        }
    },
    modalTicketBr:{
        alertMessage: {
            title: "Atención",
            description: "Las fechas de su preferencia para programar Mantenimiento, Revisión Periódica o Cambio de Neumáticos no deben exceder los 45 días de anticipación."
        },
        inputs:[
            /*00*/"DDD + Teléfono del conductor",
            /*01*/"Kilometraje",
            /*02*/"Estado",
            /*03*/"Ciudad",
            /*04*/"Región de preferencia para el servicio",
            /*05*/"Posible fecha 1",
            /*06*/"Posible fecha 2",
            /*07*/"Posible fecha 3",
            /*08*/"¿Carro blindado?",
            /*09*/"Ida y vuelta",
            /*10*/"Ingrese la dirección donde se debe recoger el vehículo",
            /*11*/"Ingrese la dirección donde se debe devolver el vehículo",
            /*12*/"Ingrese la dirección donde se debe recoger y devolver el vehículo",
            /*13*/"Información adicional",
            /*14*/"Nombre",
            /*15*/"Email",
            /*16*/"Describa aquí si el vehículo tiene un ruido o problemas adicionales como aire acondicionado y otros...",
            /*17*/"Placa",
            /*18*/"DDD + Teléfono del responsable"
        ],
        levaETraz: [
            /*00*/"¿Ida y vuelta?",
            /*01*/"No",
            /*02*/"Ida",
            /*03*/"Vuelta",
            /*04*/"Ida y vuelta"
        ],
        errorMessages:[
            /*00*/"Por favor ingrese un número de teléfono o celular",
            /*01*/"Por favor seleccione un vehículo antes de continuar",
            /*02*/"Por favor ingrese el nombre del responsable del servicio",
            /*03*/"Por favor ingrese el correo electronico del responsable del servicio",
            /*04*/"Por favor ingrese un correo electronico valido para el responsable del servicio",
            /*05*/"Por favor ingrese un número de teléfono o celular para el responsable del servicio",
            /*06*/"Por favor llene el campo de kilometraje",
            /*07*/"por favor seleccione un estado para agendar el servicio",
            /*08*/"Por favor seleccione una ciudad para agendar el servicio",
            /*09*/"Por favor llene el campo de información adicional",
            /*10*/"Indique si el automóvil cuenta con blindaje",
            /*11*/"Indique si requiere del servicio de ida y vuelta",
            /*12*/"Las fechas de atención no pueden ser las mismas",
            /*13*/"Por favor indique la dirección para efectuar el servicio de ida y vuelta",
            /*14*/"Por favor seleccione la región en la cual desea realizar el servicio",
            /*15*/"No se pueden agendar servicios en fines de semana",
            /*16*/"La hora de servicio seleccionada no es valida, el horario de atención es de 7h a 17h",
            /*17*/"Por favor seleccione una fecha para agendar el servicio",
            /*18*/"Lo sentimos por el momento no podemos dar de alta el servicio solicitado",
            /*19*/"Lo sentimos ocurrió un error en nuestro servidor al momento de dar de alta el servicio solicitado, intenta de nuevo más tarde",
            /*20*/"La cita debe solicitarse con 48 horas de anticipación",
            /*21*/"La fecha de programación no puede ser menor que la fecha de hoy"
        ],
        successMessages: [
            /*00*/"Su solicitud se recibió correctamente. En breve, uno de nuestros administradores lo contactará con los detalles de su cita"
        ],
        infoMessages:[
            /*00*/"Nuestro equipo evaluará la elegibilidad para el servicio de transporte de acuerdo con el contrato de su empresa",
            /*01*/"El contrato seleccionado está asignado a un conductor, añade la información faltante del responsable del servicio",
            /*02*/"El contrato se encuentra asignado a más de un conductor, añade manualmente la información del responsable del servicio",
            /*03*/"El contrato no se encuentra asignado a ningún conductor, añade manualmente la información del responsable del servicio"
        ]
    },
    modalTicketGnr:{
        inputs:[
            /*00*/"Placa",
            /*01*/"Teléfono",
            /*02*/"Kilometraje",
            /*03*/"Estado",
            /*04*/"Ciudad",
            /*05*/"Posible fecha 1",
            /*06*/"Posible fecha 2",
            /*07*/"Información adicional",
            /*08*/"Nombre y Apellido",
            /*09*/"Email",
            /*10*/"Describa aquí si el vehículo tiene un ruido o problemas adicionales como aire acondicionado y otros...",
            /*11*/"Departamento",
            /*12*/"Regíon",
            /*13*/"Tipo de servicio",
            /*14*/ "Teléfono corporativo",
            /*15*/ "Correo corporativo"
        ],
        serviceType:[
            /*00*/"Mantenimiento Preventivo",
            /*01*/"Mantenimiento Correctivo"
        ],
        errorMessages:[
            /*00*/"Por favor ingrese un número de teléfono o celular",
            /*01*/"Por favor seleccione un vehículo antes de continuar",
            /*02*/"Por favor ingrese el nombre del responsable del servicio",
            /*03*/"Por favor ingrese el correo electronico del responsable del servicio",
            /*04*/"Por favor ingrese un correo electronico valido para el responsable del servicio",
            /*05*/"Por favor ingrese un número de teléfono o celular para el responsable del servicio",
            /*06*/"Por favor llene el campo de kilometraje",
            /*07*/"Por favor seleccione un estado para agendar el servicio",
            /*08*/"Por favor seleccione una ciudad para agendar el servicio",
            /*09*/"Por favor llene el campo de información adicional",
            /*10*/"Las fechas de atención no pueden ser las mismas",
            /*11*/"No se pueden agendar servicios en fines de semana",
            /*12*/"La hora de servicio seleccionada no es valida, el horario de atención es de 8h a 20h",
            /*13*/"Esta unidad ya cuenta con un servicio similar en proceso",
            /*14*/"Por favor seleccione una fecha para agendar el servicio",
            /*15*/"No es posible agendar servicios en Domingo",
            /*16*/"La hora de servicio seleccionada no es valida, el horario de atención en sabados es de 8h a 14h",
            /*17*/"La hora de servicio seleccionada no es valida, el horario de atención es de 8h a 18h",
            /*18*/"Seleccione el tipo de servicio que desea solicitar",
            /*19*/"La cita debe solicitarse con 48 horas de anticipación",
            /*20*/"La fecha de programación no puede ser menor que la fecha de hoy",
            /*21*/"Por favor seleccione un departamento para agendar el servicio",
            /*22*/"Por favor seleccione una región para agendar el servicio",
            /*23*/"La hora de servicio seleccionada no es valida, el horario de atención es de 8:30h a 13h y de 14h a 17:30h",
            /*24*/"La hora de servicio seleccionada no es valida, el horario de atención en sabados es de 8:30h a 13h"
        ],
        successMessages: [
            /*00*/"Su solicitud se recibió correctamente. En breve, uno de nuestros administradores lo contactará con los detalles de su cita"
        ],
        infoMessages:[
            /*00*/"El contrato seleccionado está asignado a un conductor, añade la información faltante del responsable del servicio",
            /*01*/"El contrato se encuentra asignado a más de un conductor, añade manualmente la información del responsable del servicio",
            /*02*/"El contrato no se encuentra asignado a ningún conductor, añade manualmente la información del responsable del servicio"
        ],
        noContractMessage: "No hay contratos disponibles"
    },
    checkPointsTimeline: {
        titles:[
            /*00*/ "Ticket abierto",
            /*01*/ "Cita de servicio",
            /*02*/ "¿Llegaste al taller?",
            /*03*/ "Presupuesto en preparación!",
            /*04*/ "Presupuesto de aprobación",
            /*05*/ "Entrega de su vehículo",
            /*06*/ "¿Retiro su vehículo?",

            /*07*/ "Check in realizado",
            /*08*/ "Presupuesto enviado a ALD",
            /*09*/ "Presupuesto aprobado",
            /*10*/ "¡Check out realizado!",
            /*11*/ "Presupuesto reprobado"
        ],
        generalMessages: [
            /*00*/ "¡Estamos trabajando en eso! \n Pronto recibirá información \n de su cita",
            /*01*/ "¡Realize su check in para mas agilidad!",
            /*02*/ "El taller está analizando su vehículo \n y le enviará un presupuesto para su aprobación.",
            /*03*/ "¡Estamos trabajando en eso! \n Pronto aprobaremos el mantenimiento de su vehículo",
            /*04*/ "Entrega prevista",
            /*05*/ "¡Realize su Check out aqui!",
            /*06*/ "Pronto recibirá la hora de entrega de su vehiculo",
            /*07*/ "Entrega de su vehiculo ",
            /*08*/ "En caso de duda, llame a nuestro centro de asistencia",
            /*09*/ "Ticket Cancelado",
            /*10*/ "Haga clic aquí, realiza tu check in y garantiza más agilidad durante todo el proceso.",
            /*11*/ "\n \n * Antes de retirar su vehículo, confirme con el taller el horario, ya que durante el servicio en el vehículo pueden ocurrir eventos imprevistos.",
            /*12*/ "¡Haz clic aquí y realiza tu check out!"
        ],
        ticketType: [
            /*00*/ "Mantenimiento | Revisión periodica",
            /*01*/ "Mantenimiento Preventivo",
            /*02*/ "Mantenimiento",

            /*03*/ "Mantenimiento Correctivo",
            /*04*/ "Revisión técnica",

            /*05*/ "Neumáticos",
            /*06*/ "Llantas",

            /*07*/"Revisión técnico mecánica",

            /*08*/"Reparaciones",

            /*09*/"Batería",



        ]
    },
    bireport: {
        indicators: "Indicadores",
        canbus: "Canbus",
        messageSoon: "Muy pronto en tu portal Fleet Manager, ALD pondrá a tu disposición esta nueva funcionalidad donde podrás visualizar reportes de tu flota. Todo esto desde la comodidad de tu portal Fleet Manager"
    },
    aldnet: {
        title: "ALD NET",
        message: "Abrir ALDNET en una nueva pestaña",
        instrucctions: "El nombre de usuario y la contraseña para acceder a ALD NET es el mismo que utilizó para acceder al portal anterior. Si no lo recuerda, haga clic en “Olvidé mi contraseña”",
        button: "Ir a ALDNET"
    },
    alertMessage: {
        message: "La sesión ha finalizado para seguir utilizando la plataforma es necesario iniciar sesión nuevamente"
    },
    PrivacyPolicy: {
        terms: 'Términos y Condiciones de Uso',
        privacy: 'Política de Privacidad',
        cookies: 'Política de Cookies',
        advice: 'Antes de comenzar, lea y acepte nuestras políticas y términos vigentes:',
        accept:'He leído y acepto'
    },
    documents: {
        messageSoon: "Muy pronto aquí en tu portal MyALD para gestores de flotas, pondremos a tu disposición esta nueva función con la que podrás administrar y distribuir documentos entre tus conductores de forma rápida y cómoda",
        headers: [
            /*00*/ "Tipo",
            /*01*/ "Titulo",
            /*02*/ "Categoría",
            /*03*/ "Dato de envío",
            /*04*/ "Notas",
            /*05*/ "Tipo de documento"
        ],
        title: "Mis Documentos",
        subtitle: "Histórico de contratos",
        modalTitle: "Cargar documentos",
        buttonTitle: "Cargar archivo",
        deleteMessage: "Estas seguro en eliminar el documento?",
        modal: [
            /*00*/ "Nombre del documento",
            /*01*/ "Categoría",
            /*02*/ "Notas"
        ],
        options: [
            /*00*/ "Administrador de flota",
            /*01*/ "Conductor",
            /*02*/ "General"
        ],
        alertMessages: [
            /*00*/ "No se encontraron documentos",
            /*01*/ "Documento eliminado correctamente",
            /*02*/ "Es necesario que seleccione un archivo para subir",
            /*03*/ "Es necesario que seleccione al menos un cliente para dar de alta el documento",
            /*04*/ "La extensión del archivo no es válida, debe ser .pdf",
            /*05*/ "La extensión del archivo no es válida, debe ser .xlsx",
            /*06*/ "El nombre del archivo no es válido, no puede contener caracteres especiales",
            /*07*/ "El tamaño del archivo es muy grande (3MB máximo)",
            /*08*/ "Nombre de archivo inválido",
        ],
    },
    gloveBox: {
        documentType: "Tipo de documento",
        gloveBoxApp: "Glove Box App (MI AUTO)",
        documentApp: "Documentos App",
        descriptionDocuments: "Aquí puede publicar documentos oficiales para los conductores de su flota, como políticas de flota, infografías de procesos, etc. Los documentos se pueden ver en la aplicación móvil en Políticas y documentos.",
        descriptionGloveBox: "Acá quedan los documentos del vehículo ",
        upload: "Subir Archivo",
        saving: "Cargando",
        save: "Cargado",
        saveFile: "GUARDAR",
        alertMessages: [
            /*01*/ "Hubo un error. Vuelva a intentarlo",
            /*02*/ "¡Subida de archivo realizada con éxito!",
            /*03*/ "No se encontraron contratos",
            /*04*/ "La extensión del archivo no es válida, debe ser .pdf",
        ]
    },
    Notification: {
        description: "Aquí puede publicar anuncios generales, noticias rápidas y eventos para los conductores de su flota. Las notificaciones se pueden ver en la opción \"Notificaciones\" en el menú lateral de la aplicación del conductor.",
        deleteNotificationTitle: "¿Estás seguro de que quieres borrar la notificación?",
        deleteNotificationText: "Una vez eliminada la notificación, deberá ponerse en contacto con el administrador para recuperarla de nuevo.",
        noNotifications: "No hay notificaciones por el momento",
        deleteNotificationSuccess: "Notificación eliminada correctamente",
        appendix: "Anexo",
        category: "Categoría",
        title: "Titulo",
        date: "Día de publicación",
        createDate: "Fecha de creación",
        link: "Link adicional",
        newNotification: "Nueva notificación",
        addNotification: {
            addNotification: "Crear nueva notificación",
            document: "Archivo PDF",
            title: "Titulo *",
            type: "Tipo *",
            description: "Descripción",
            date: "Fecha de publicación",
            hourDate: "Hora de publicación",
            link: "Link adicional",
            selectCompany: "Seleccione las empresas",
            userType: "Tipo de usuário",
            userFM: "FM",
            userDriver: "Drivers",
            selectAll: "Seleccionar todo",
            accept: "Guardar",
            labelMaxLenght: "Máximo 90 caracteres",
            labelUsers: "Seleccione todos o arrastre aquí los usuarios que deben recibir esta comunicación",
            customerCare: "Asigne Cliente"
        },
        AlertsMassage: [
            "Ocurrió un error al momento de crear tu notificación", // 0
            "Se elimino correctamente la notificación", // 1
            "¡Subida de notificación realizada con éxito!", // 2
            "No se encontraron notificaciones creadas", // 3
            "Añade la información faltante para subir la notitifación", //4
            "No tiene notificaciones por el momento" //5
        ],
        FilterLabels: {
            category: "Categoria",
            title: "Titulo",
            createDate: "Fecha de creación",
            publishDate: "Fecha de publicación"
        }
    },
    AldSacTimeLine: {
        titles: [
            /*00*/ "Ticket abierto",
            /*01*/ "Cita de servicio",
            /*02*/ "¿Llegaste al taller?",
            /*03*/ "Presupuesto en preparación!",
            /*04*/ "Presupuesto de aprobación",
            /*05*/ "Entrega de su vehículo",
            /*06*/ "¿Retiro su vehículo?",
            /*07*/ "Check in realizado",
            /*08*/ "Presupuesto enviado a ALD",
            /*09*/ "Presupuesto aprobado",
            /*10*/ "¡Check out realizado!",
            /*11*/ "Presupuesto reprobado"
        ],
        generalMessages: [
            /*00*/ "¡Estamos trabajando en eso! \n Pronto recibirá información \n de su cita",
            /*01*/ "¡Realize su check in para mas agilidad!",
            /*02*/ "El taller está analizando su vehículo \n y le enviará un presupuesto para su aprobación.",
            /*03*/ "¡Estamos trabajando en eso! \n Pronto aprobaremos el mantenimiento de su vehículo",
            /*04*/ "Entrega prevista",
            /*05*/ "¡Realize su Check out aqui!",
            /*06*/ "Pronto recibirá la hora de entrega de su vehiculo",
            /*07*/ "Entrega de su vehiculo ",
            /*08*/ "En caso de duda, llame a nuestro centro de asistencia",
            /*09*/ "Ticket Cancelado",
            /*10*/ "Haga clic aquí, realiza tu check in y garantiza más agilidad durante todo el proceso.",
            /*11*/ "* Antes de retirar su vehículo, confirme con el taller el horario,\nya que durante el servicio en el vehículo pueden ocurrir eventos imprevistos.",
            /*12*/ "¡Haz clic aquí y realiza tu check out!"
        ],
        alerts: [
            // Alerta para cancelación de servicio
            /*00*/ 'Cancelar',
            /*01*/ '¿Desea realmente cancelar su servicio?',

            // Alerta para reagendar servicio
            /*02*/ 'Reagendar',
            /*03*/ '¿Desea reagendar su servicio?',

            // Alerta para CheckIn
            /*04*/ 'Check in',
            /*05*/ '¿Desea informar a ALD que se encuentra con su vehiculo en el taller o agencia?',
            /*06*/ 'Si',
            /*07*/ 'Padronizar novamente',
            /*08*/ 'No',

            // Alerta al cancelar servicio correctamente
            /*09*/ 'Tu solicitud de cancelación fue enviada correctamente',

            // Alerta para error al cancelar servicio
            /*10*/ 'Se produjo un error al intentar cancelar el servicio solicitado. \nIntente nuevamente y si el problema persiste, comuníquese con el Centro de asistencia al conductor para solicitar la cancelación',

            // Alerta al reagendar servicio correctamente
            /*11*/ 'Su solicitud de reprogramación se envió correctamente. \nTan pronto como nuestro equipo programe el servicio, recibirá una confirmación. ¡Este atento!',

            // Alert para error al reagendar servicio
            /*12*/ 'Se produjo un error al intentar reprogramar el servicio. \nIntente nuevamente y si el problema persiste, comuníquese con el Centro de asistencia al conductor',

            //Check-in/out
            /*13*/ 'Confirmación exitosa',

            //Error en la obtención de tickets
            /*14*/ 'Ocurrio un error al obtener la información del servicio. \nIntente nuevamente y si el problema persiste, comuníquese con el Centro de asistencia al conductor',

            //Mensajes para checkIn y CheckOut
            /*15*/ "Check In",
            /*16*/ "¿Deseas confirmar a ALD Automotive el ingreso de tu unidad al punto de servicio asignado?",
            /*17*/ "Check Out",
            /*18*/ "¿Deseas confirmar a ALD Automotive que tu unidad te fue entregada?",
            /*19*/ "Ocurrio un error en la confirmación"
        ],
        Button: [
            /*00*/ '¿Cancelar cita?',
            /*01*/ 'Solicitando cancelación de serviço',
            /*02*/ 'Solicitando nueva cita',
            /*03*/ 'Check In',
            /*04*/ 'Check Out'
        ]
    },
    home: {
        products: [
            /*00*/ "Inteligencia",
            /*01*/ "Movilidad",
            /*02*/ "Desempeño",
            /*03*/ "Sostenibilidad",
            /*04*/ "Productos ALD",
            /*05*/ "Conoce más acerca de nuestros productos!",
            /*06*/ "Próximamente más productos !!!"
        ],
        blog:[
            /*00*/ "Noticias ALD",
            /*01*/ "ALD está para ayudarte ven y revisa nuestro Blog donde encontraras ideas y soluciones enfocadas en la movilidad.",
            /*02*/ "Haz clic y conoce mas..."
        ],
        news: [
            /*00*/ "Conoce las últimas noticias sobre Movilidad, para conocer mas de como ALD puede ayudarte a ti y a tu flota a crecer"
        ]
    },
    Months: {
        completos: [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre"
        ],
        abreviatura: [
            "Ene",
            "Feb",
            "Mzo",
            "Abr",
            "May",
            "Jun",
            "Jul",
            "Agt",
            "Sep",
            "Oct",
            "Nov",
            "Dic"
        ]
    },
    ModuleBI: {
        title: 'AVISO',
        Paragraph_1: 'Su usuario de MyALD aún no tiene los permisos necesarios para acceder a los Reportes de Flota.',
        Paragraph_2: 'Después de la liberación de su acceso al Portal, los permisos de visualización de Reportes toman 24h en ser habilitados. Si este periodo de tiempo venció, le pedimos entre en contacto con nosotros mediante:',
        Paragraph_3: 'Informando que no consigue visualizar los Reportes de Flota en MyALD Manager.'
    },
    ModuleAgreements: {
        BudgetApproval: 'Gestionar Presupuesto',
        ChooseCustomer:'Debe seleccionar un cliente para ver los datos',
        DoesntHaveBudget: 'Este cliente seleccionado no tiene nuevos presupuestos para aprobar',
        Filters: {
            clean: 'Limpiar Filtros',
            clients: 'Cliente',
            plates: 'Placa',
            filters: 'Filtros',
            pedding: 'Pendientes',
            approved: 'Aprobados',
            rejected: 'Rechazados',
            cancel: 'Cancelar',
            apply: 'Aplicar',
        },
        table: {
            idSat: 'Presupuesto ID',
            plate: 'Placa',
            model: 'Modelo',
            enterprise: 'Empresa',
            dateService: 'Cita de servicio',
            description: 'Descripción',
            price: 'Precio (con IVA)',
            provider: 'Proveedor',
            status: 'Estatus',
            actions: 'Acciones',
            sfTicket: 'Ticket (SF Case)',
        },
        actions: {
            view: 'Ver Presupuesto',
            comments: 'Comentarios',
            history: 'Historial',
            attachment:'Ver documento',
        },
        budget: {
            id: 'Presupuesto ID',
            vehicle: 'Vehículo',
            plate: 'Placa',
            km: 'Km',
            vin: 'VIN',
            driver: 'Conductor',
            customer: 'Cliente',
            supplier: 'Proveedor',
            workshop: 'Taller',
            quantity: 'Cantidad',
            description: 'Descripción',
            price: 'Precio (con IVA)',
            priority: 'Prioridad',
            rejected: 'Rechazado Completo',
            partialAproval: 'Autorización Parcial',
            fullAproval: 'Autorización Completa',
            salesForceCaseNumber: 'Numero de Caso SF',
            low: 'Baja',
            medium: 'Media',
            high: 'Alta',
            parts: 'Partes',
            manoObra: 'Mano de Obra',
            typeCost: 'Tipo de Costo',
            rebill: 'REFACTURA',
            maintenance: 'Mantenimiento',
        },
        comments: {
            comments: 'Comentarios', 
            noComments: 'No hay comentarios',
            hasNoComments: 'Este presupuesto no tiene comentarios',
            insertComments: 'Agregar Comentarios',
            writeComments: 'Escribir observaciones aqui',
        },
        alerts: {
            selectItems: 'Selecciona al menos un elemento para continuar.',
            procesingRequest: 'ALD se encuentra atendiendo su aprobación, no es necesario volver a autorizar o rechazar el presupuesto.',
            workingNewBudget: 'ALD se encuentra generando un nuevo presupuesto para usted, una vez este disponible podrá visualizarlo en el portal.',
            selectOneItem: 'Selecciona al menos un elemento para continuar.',
            rejectedBudget: [
                'Esta por rechazar el presupuesto por completo.', // 0
                'Desea continuar?', //1
            ],
            fullApprovalBudget: [
                'Esta por realizar la aprobación completa de su presupuesto', // 0
                'con un costo total de', //1
                'esta seguro de continuar?' //2
            ],
            partialBudget: [
                'Estás intentando enviar una aprobación parcial. Estaremos enviando su solicitud parcial a ALD.', // 0
                'Esta seguro de continuar con la solicitud? ' // 1
            ],
            itemsRejected: '* Ítems Rechazados por el Fleet Manager:',
            itemsApproved: '* Ítems Aprobados por el Fleet Manager:',
            errorFile: "No se pudo cargar el archivo, la estructura del attachment no es válida",
            emptyAttachment: "Aun no se encuentra disponible ningún Attachment",
        }
    }
}